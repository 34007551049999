import { makeStyles } from '@material-ui/core';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';
import { COLORS } from '../../styles/color';

export default makeStyles((theme) => ({
  personPage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  headerTitle: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '-0.3px',
    color: '#707070',
    textTransform: 'uppercase',
  },
  mainPerson: {
    background: COLORS.white,
    width: 'calc(100% - 350px)',
    padding: '0px 15px 15px',
    minHeight: 'calc(100vh - 110px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minHeight: 'unset',
    },
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: ' center',
    borderBottom: '1px solid #C9C9C9',
    position: 'sticky',
    top: '110px',
    zIndex: 2,
    backgroundColor: COLORS.white,
  },
  videoType: {
    padding: '8px 12px',
    color: '#CECECE',
    '&:focus,&:hover': {
      color: '#3157A7',
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  videoTypeSelected: {
    color: '#3157A7',
  },
  videoListContainer: {
    padding: '45px 23px',
    [theme.breakpoints.down('md')]: {
      padding: '20px 12px',
    },
  },
  videoNotFound: {
    fontSize: '18px',
  },
  videoItem: {
    padding: '15px !important',
  },
  thumbnailPlay: {
    display: 'none',
  },
  thumbnailContainer: {
    position: 'relative',
    height: '219px',
    overflow: 'hidden',
    display: 'block',
    borderRadius: '10px',
    '&:hover $thumbnailPlay': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      '& img': {
        width: '54px',
      },
    },
  },
  videoStatus: {
    position: 'absolute',
    display: 'inline-block',
    padding: '5px',
    fontSize: '12px',
    top: '0px',
    left: '0px',
    borderRadiusTopLeft: '10px',
    color: '#FFFFFF',
  },
  videoStatus1: {
    backgroundColor: '#FF269E',
  },
  videoStatus2: {
    backgroundColor: '#4483D9',
  },
  videoStatus3: {
    backgroundColor: '#2B2B2B',
  },
  thumbnail: {
    height: '100%',
    objectFit: 'cover',
    objectPosition: '50% 25%',
    borderRadius: '10px',
    width: '100%',
    backgroundColor: '#000000',
  },
  videoFooter: {
    marginTop: '6px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  videoTitle: {
    color: '#707070',
    fontWeight: 700,
    marginTop: '5px',
    marginBottom: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
  videoActions: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    color: '#707070',
    fontWeight: 500,
  },
  iconDel: {
    color: '#E21B1B',
  },
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  ...modalStyle(theme),
  removePadding: {
    paddingTop: '0px',
  },
  playVideo: {
    maxHeight: '500px',
    width: '100%',
  },
  ...buttonStyle,
  socialButton: {
    marginBottom: '24px',
    '&:not(:first-child)': {
      marginLeft: '8px',
      marginTop: '8px',
    },
    '& svg': {
      borderRadius: '50%',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
