import { actionTypes } from './actions';

export const initialState = {
  accessToken: null,
  userInfo: null,
  isLoggingIn: false,
  message: null,
  verifying: false,
  firebaseToken: null,
  updateFirebaseTokenError: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN:
      return { ...state, isLoggingIn: true };

    case actionTypes.LOGIN_SUCCESS: {
      const { accessToken } = action;
      return { ...state, isLoggingIn: false, accessToken };
    }

    case actionTypes.LOGIN_FAILURE: {
      return { ...state, isLoggingIn: false };
    }

    case actionTypes.LOGIN_WITH_FACEBOOK: {
      return { ...state, isLoggingIn: true };
    }

    case actionTypes.LOGIN_WITH_FACEBOOK_SUCCESS: {
      const { payload: userInfo } = action;
      return {
        ...state,
        isLoggingIn: true,
        accessToken: userInfo.token,
        userInfo,
      };
    }

    case actionTypes.LOGIN_WITH_FACEBOOK_FAILURE: {
      const { payload: message } = action;
      return { ...state, isLoggingIn: false, message };
    }

    case actionTypes.VERIFY_TOKEN: {
      return { ...state, verifying: true };
    }

    case actionTypes.VERIFY_TOKEN_SUCCESS: {
      const { accessToken, userInfo } = action;
      return { ...state, verifying: false, accessToken, userInfo };
    }

    case actionTypes.VERIFY_TOKEN_FAILURE: {
      return { ...state, verifying: false };
    }

    case actionTypes.GET_USER_INFO_SUCESS: {
      const { userInfo } = action;
      return { ...state, userInfo };
    }

    case actionTypes.UPDATE_TOTAL_VIDEO: {
      const { totalVideoCurrentUse } = action;
      const { userInfo = {} } = state;
      const newUserInfo = { ...userInfo, totalVideoCurrentUse };
      return { ...state, userInfo: newUserInfo };
    }

    case actionTypes.LOGOUT: {
      return { ...initialState };
    }

    case actionTypes.UPDATE_MEMBER_FIREBASE_TOKEN_SUCCESS: {
      const { firebaseToken } = action;
      return { ...state, firebaseToken, updateFirebaseTokenError: false };
    }
    case actionTypes.UPDATE_MEMBER_FIREBASE_TOKEN_FAILURE: {
      return { ...state, updateFirebaseTokenError: true };
    }

    default:
      return { ...state };
  }
}
