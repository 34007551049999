import { makeStyles } from '@material-ui/core';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import { FEATURE_COLORS, COLORS } from '../../../styles/color';

export default makeStyles((theme) => ({
  sidebarPerson: {
    width: '350px',
    flexShrink: 0,
    '& img': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  stickyContainer: {
    position: 'sticky',
    top: '110px',
    maxHeight: 'calc(100vh - 110px)',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: '0px',
      maxHeight: 'unset',
    },
  },
  backgroundInfo: {
    width: '100%',
    height: '150px',
  },
  inforPerson: {
    padding: '15px',
    marginTop: '-55px',
    '& .picture-container': {
      '& .description': {
        display: 'none',
      },
    },
  },
  infoUser: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiButton-root:hover': {
      background: `${COLORS.white}`,
      color: `${FEATURE_COLORS.bgButtonVideo}`,
      border: `1px solid ${FEATURE_COLORS.bgButtonVideo}`,
    },
  },
  infoContainer: {
    width: 'calc(100% - 120px)',
    marginLeft: '22px',
  },
  username: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& h3': {
      overflow: 'inherit',
      textOverflow: 'inherit',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '28px',
      margin: '0',
      color: `${COLORS.white}`,
    },
  },
  textH4: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '21px',
    marginTop: '20px',
  },
  textP: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    fontSize: '16px',
    lineHeight: '21px',
    letterSpacing: '-0.3px',
  },
  btnBorderRadius: {
    boxSizing: 'border-box',
    borderRadius: '20px',
  },
  btnUpgrade: {
    background: `${FEATURE_COLORS.bgButtonVideo}`,
    border: `1px solid ${FEATURE_COLORS.bgButtonVideo}`,
    color: `${COLORS.white}`,
    fontSize: '14px',
    padding: '6px 8px',
    fontWeight: 'normal',
    lineHeight: '16px',
  },
  infoVideo: {
    '& p': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '21px',

      '& img': {
        width: '21px',
        marginRight: '15px',
      },
    },
  },
  templateContainer: {
    padding: '0px 8px',
  },
  template: {
    padding: '6px 7px !important',
    '& .MuiButton-root': {
      padding: '0px',
      borderRadius: '10px',
    },
    '& img': {
      borderRadius: '10px',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '14px',
    borderBottom: '1px solid #C9C9C9',
    paddingBottom: '8px',
  },
  paginationButton: {
    minWidth: '38px',
  },
  currentPage: {
    color: '#7D7D7D',
    minWidth: '26px',
    textAlign: 'center',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
  btnContainer: {
    // margin: '12px 15px 0px',
    height: '100%',
  },
  btnCreateVideo: {
    color: `${COLORS.white}`,
    background: `${FEATURE_COLORS.bgButtonVideo}`,
    border: `1px solid ${FEATURE_COLORS.bgButtonVideo}`,
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    '&:hover': {
      background: `${FEATURE_COLORS.bgButtonVideo}`,
    },

    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  div50: {
    width: '100%',
    '&:last-child': {
      marginLeft: '10px',
    },
  },
  btnCreated: {
    display: 'flex',
    padding: '0 15px',
  },
  infoValue: {
    flex: '1',
  },
  iconEditor: {
    padding: '0px',
    marginLeft: '15px',
    minWidth: 'unset',
    '& img': {
      marginRight: '0px !important',
    },
  },
  ...buttonStyle,
}));
