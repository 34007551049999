import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  Input,
  Slider,
  TextareaAutosize,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PauseIcon from '@material-ui/icons/Pause';
import ArrowRightAltSharpIcon from '@material-ui/icons/ArrowRightAltSharp';
import PlayArrowSharpIcon from '@material-ui/icons/PlayArrowSharp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';

import classNames from 'classnames';
import apis from 'apis';
import CustomButton from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NavPills from 'components/NavPills/NavPills';
import Table from 'components/Table/Table';
import useStyles from './index.style';
import { IMAGES, ROUTES } from '../../../constants';

export default function MainContent({ setListDataSentence, getLengthContent }) {
  const classes = useStyles();
  const { t } = useTranslation(['greenScreen']);
  const audioRef = useRef(null);

  const { userInfo } = useSelector((state) => state.auth);
  const { maxText } = userInfo;

  const [speedInSlider, setSpeedInSlider] = useState(1.0);
  const [valueFileAudio, setValueFileAudio] = useState('');
  const [playingConvert, setPlayingConvert] = useState(true);
  const [loadingConvert, setLoadingConvert] = useState(false);
  const [srcAudio, setSrcAudio] = useState('');
  const [language, setLanguage] = useState('vn');
  const [voice, setVoice] = useState('');
  const [dataVoices, setDataVoices] = useState([]);
  const [voiceInfos, setVoiceInfos] = useState();
  const [listSentence, setListSentence] = useState([
    { text: '', rate: 1, isChecked: false },
  ]);
  const [valueTitle, setValueTitle] = useState('');
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentIndexPlay, setCurrentIndexPlay] = useState(null);

  const [isBlur, setIsBlur] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lengthContent, setLengthContent] = useState(0);
  const [alert, setAlert] = useState(null);
  const [isCanDemo, setIsCanDemo] = useState(false);

  const hideAlert = () => {
    setAlert(null);
  };

  const totalLength = useMemo(() => {
    return listSentence.reduce((sum, a) => sum + a.text.length, 0);
  }, [JSON.stringify(listSentence)]);

  const onPaste = (event, index) => {
    setIsBlur(false);
    let textCopy = event.clipboardData.getData('text/plain');
    let textContentCopy = event.currentTarget.textContent;
    let textindex = listSentence[index].text;
    setTimeout(async () => {
      setLoading(true);

      textContentCopy += textCopy;
      // if (lengthContent > maxText) {
      //   textContentCopy = textContentCopy.slice(0, maxText);
      // } else {
      //   let _total = maxText - totalLength;
      //   textContentCopy = textContentCopy.slice(0, textindex.length + _total);
      // }
      const res = await apis.resource.splitSentence({
        input_text: textContentCopy,
      });
      const newDataObject = res.map((text) => ({
        text,
        rate: 1,
        isChecked: false,
      }));

      const newData = [...listSentence];
      newData.splice(index, 1, ...newDataObject);
      setListSentence(newData);
      setIsBlur(true);
      setLoading(false);
      setLengthContent(newData.reduce((sum, a) => sum + a.text.length, 0));
    }, 100);
  };

  const onBlur = (e, index) => {
    setIsBlur(true);
    if (isBlur) {
      const newDataObject = [
        {
          text: e.currentTarget.textContent,
          rate: 1,
          isChecked: false,
        },
      ];
      const newData = [...listSentence];
      newData.splice(index, 1, ...newDataObject);
      setLengthContent(newData.reduce((sum, a) => sum + a.text.length, 0));

      // setLengthContent(totalLength);
      setListSentence(newData);
    }
  };

  const onChange = (e, index) => {
    setIsCanDemo(true);
    const textCurrent = e.target.value;
    const textIndex = listSentence[index].text;

    const newListSentence = listSentence.map((ele, index) => {
      if (ele.text === textIndex) {
        return { ...ele, text: e.target.value };
      }
      return ele;
    });

    let _totalLength = totalLength;
    _totalLength = _totalLength + (textCurrent.length - textIndex.length);

    setLengthContent(_totalLength);
    setListSentence(newListSentence);

    if (lengthContent > maxText) {
      return false;
    }
  };

  const onKeyDown = (e) => {
    if (lengthContent > maxText && e.keyCode !== 8) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
        >
          {t('maxText')}
        </SweetAlert>,
      );
      return false;
    }
  };

  const handleSliderChange = (event, newValue) => {
    setSpeedInSlider(newValue);
  };

  const handelChangeRate = (index) => {
    const sentence = listSentence[index];
    setCurrentIndex(index);
    setSpeedInSlider(sentence.rate);
    setIsCanDemo(true);
  };

  const onChangeFile = async (e) => {
    let formData = new FormData();
    if (e.target.files.length) {
      const file = e.target.files[0];
      formData.append('image', file);
      const data = await apis.resource.uploadFile({ formData });
      setValueFileAudio(data.path);
    } else setValueFileAudio('');
  };
  const onAcceptRateVoice = () => {
    const newListSentence = listSentence.map((ele, index) => {
      if (index === currentIndex) return { ...ele, rate: speedInSlider };
      return ele;
    });

    setListSentence(newListSentence);
    setCurrentIndex(null);
  };

  const onPlayConvertText = async (index) => {
    const { text = '', rate } = listSentence[index] || {};
    if (!text.trim().length) return;
    const params = {
      inputText: text.trim(),
      voice: voice,
      rate: rate,
    };

    if (srcAudio && index === currentIndexPlay) {
      if (playingConvert) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      if (!isCanDemo) {
        return;
      }
    }
    audioRef.current.pause();
    setCurrentIndexPlay(index);
    setLoadingConvert(true);
    try {
      const res = await apis.voice.convertTTS(params);
      const { error, link } = res;
      if (error === 0) {
        setSrcAudio(link);
        audioRef.current.load();
        audioRef.current.play();
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoadingConvert(false);
    setIsCanDemo(false);
  };

  const handelDelete = (index) => {
    const sentence = listSentence[index];
    const isData = listSentence.indexOf(sentence);
    let newData = listSentence;
    if (isData > -1) {
      newData.splice(isData, 1);
      if (index === currentIndexPlay) {
        setCurrentIndexPlay(null);
        audioRef.current.pause();
      }
    }
    setListSentence(listSentence.filter((item) => item.index !== index));
    if (listSentence.length === 0) {
      setListSentence([{ text: '', rate: 1, isChecked: false }]);
    }
    let _totalLength = totalLength;
    _totalLength = _totalLength - sentence.text.length;
    setLengthContent(_totalLength);
  };

  const isAllCheckbox = useMemo(() => {
    return listSentence.every((item) => {
      return item.isChecked === true;
    });
  }, [listSentence]);

  const handleChangeCheckbox = (indexCheck) => {
    const newListSentence = listSentence.map((ele, index) => {
      if (index === indexCheck) return { ...ele, isChecked: !ele.isChecked };
      return ele;
    });
    setListSentence(newListSentence);
  };

  const checkAllCheckbox = () => {
    const newListSentence = listSentence.map((ele) => {
      if (!isAllCheckbox) {
        return { ...ele, isChecked: true };
      }
      return { ...ele, isChecked: !ele.isChecked };
    });
    setListSentence(newListSentence);
  };

  const handelDeleteAll = () => {
    setCurrentIndexPlay(null);
    audioRef.current.pause();

    const newListSentence = listSentence.filter(
      (item) => item.isChecked !== true,
    );
    let totalLengthItem = 0;
    newListSentence.map((item) => {
      totalLengthItem += item.text.length;
    });
    setLengthContent(totalLengthItem);
    if (newListSentence.length === 0) {
      setListSentence([{ text: '', rate: 1, isChecked: false }]);
      return;
    }
    setListSentence(newListSentence);
  };

  const getListVoice = async () => {
    const res = await apis.voice.getListVoice();
    const { error, voice_info } = res;
    if (error === 0) {
      setVoiceInfos(voice_info);
      const newVoice = voice_info['vn'].list;
      setVoice(() => {
        return Object.keys(newVoice)[0];
      });
      setDataVoices(() => {
        return Object.keys(newVoice).map((key) => ({
          value: key,
          name: newVoice[key],
        }));
      });
    }
  };

  useEffect(() => {
    getListVoice();
  }, []);

  const handleChangeLanguage = (event) => {
    const { value } = event.target;
    setLanguage(value);
    setIsCanDemo(true);
    const newVoice = voiceInfos[value].list;
    if (newVoice) {
      setVoice(() => {
        return Object.keys(newVoice)[0];
      });
      setDataVoices(() => {
        return Object.keys(newVoice).map((key) => ({
          value: key,
          name: newVoice[key],
        }));
      });
    }
  };

  const handleChangeVoice = (e) => {
    setVoice(e.target.value);
    setIsCanDemo(true);
  };

  const handleChangeInputTitle = (event) => {
    setValueTitle(event.target.value);
  };

  const onEndedAudio = () => {
    setPlayingConvert(false);
  };

  useEffect(() => {
    setListDataSentence({
      listSentence,
      valueTitle,
      valueFileAudio,
      voice,
    });
  }, [listSentence, valueTitle, valueFileAudio, voice]);

  const tableData = () => {
    return listSentence.map((item, index) => {
      return [
        <Checkbox
          key={`checkbox-${index}`}
          color="primary"
          checked={item.isChecked}
          onChange={() => handleChangeCheckbox(index)}
        />,
        <div key={`rate-${index}`} className={classes.ratePlayAudio}>
          <img src={IMAGES.iconSpeed} alt="iconSpeed" />
          <span className={classes.span00s}>0.00s</span>
          <ArrowRightAltSharpIcon />
          <a
            href="javascript:void(0)"
            onClick={() => handelChangeRate(index)}
            className={classes.rateAudio}
          >
            x{item.rate === 1 ? '1.0' : item.rate}
          </a>
          {currentIndexPlay === index ? (
            loadingConvert ? (
              <a href="javascript:void(0)">
                <img
                  src={IMAGES.iconLoadPlay}
                  alt="iconLoadPlay"
                  className={classes.iconLoadPlay}
                />
              </a>
            ) : (
              <a
                href="javascript:void(0)"
                onClick={() => onPlayConvertText(index)}
              >
                {playingConvert ? <PauseIcon /> : <PlayArrowSharpIcon />}
              </a>
            )
          ) : (
            <a
              href="javascript:void(0)"
              onClick={() => onPlayConvertText(index)}
            >
              <PlayArrowSharpIcon />
            </a>
          )}
        </div>,
        <TextareaAutosize
          value={item.text}
          aria-label="empty textarea"
          className={classes.editor}
          placeholder={t('enterScript')}
          onPaste={(event) => onPaste(event, index)}
          onChange={(event) => onChange(event, index)}
          onKeyDown={(event) => onKeyDown(event)}
        />,
        <a
          key={`delete-${index}`}
          href="javascript:void(0)"
          className={classes.iconDelete}
          onClick={() => handelDelete(index)}
        >
          <HighlightOffIcon />
        </a>,
      ];
    });
  };

  useEffect(() => {
    tableData();
  }, [
    JSON.stringify(listSentence),
    currentIndexPlay,
    loadingConvert,
    playingConvert,
    srcAudio,
    voice,
  ]);

  const tableHead = [
    <Checkbox
      key="checkall"
      color="primary"
      checked={isAllCheckbox}
      onChange={checkAllCheckbox}
    />,
    <a key="delete-all" href="javascript:void(0)" onClick={handelDeleteAll}>
      {t('delete')}
    </a>,
    '',
    '',
  ];

  return (
    <div className={classes.mainContent}>
      <div className="header">
        <div className={classes.title}>
          <h3 className={classes.titleH3}>{t('autoLearning')}</h3>
          <h4 className={classes.titleH4}>{t('ourDeep')}</h4>
        </div>
      </div>
      <div>
        <Input
          className={classes.inputTitle}
          placeholder={t('title')}
          value={valueTitle}
          onChange={(event) => handleChangeInputTitle(event)}
        />
      </div>
      <div className={classes.tabs}>
        <NavPills
          color=""
          tabs={[
            {
              tabButton: t('script'),
              tabContent: (
                <div>
                  <p className={classes.textError}>{t('note')}</p>

                  <div className={classes.maxText}>
                    {lengthContent + '/' + maxText}
                  </div>

                  <div className={classes.table}>
                    <Table
                      tableHead={tableHead}
                      tableData={tableData()}
                      customCellClasses={[
                        classNames({
                          [classes.tableCol]: true,
                          [classes.verticalTop]: true,
                        }),
                        classNames({
                          [classes.tableCol]: true,
                          [classes.verticalTop]: true,
                        }),
                        classNames({
                          [classes.tableCol]: true,
                          [classes.verticalTop]: true,
                        }),
                        classNames({
                          [classes.tableCol]: true,
                          [classes.verticalTop]: true,
                        }),
                      ]}
                      customHeadClassesForCells={[0, 1, 2, 3]}
                    ></Table>
                    {loading && (
                      <div className={classes.loading}>
                        <img src={IMAGES.iconLoading} alt="" />
                      </div>
                    )}
                  </div>
                </div>
              ),
            },
            {
              tabButton: t('dowloadFile'),
              tabContent: (
                <div className={classes.groupUploadFile}>
                  <div className={classes.uploadFile}>
                    <CloudUploadIcon />
                    <Button className={classes.btnUploadFile}>
                      {t('chooseFile')}
                    </Button>
                    <span>{valueFileAudio}</span>
                    <p>{t('uploadFile')}</p>
                  </div>
                  <input
                    type="file"
                    className={classes.inputUploadFile}
                    onChange={onChangeFile}
                  />
                </div>
              ),
            },
          ]}
        />
        <div className={classes.boxVoiceListen}>
          <FormControl className={classes.formControl}>
            {/* <InputLabel id="demo-simple-select-label">
                          Ngôn
                        </InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={classes.selectLanguage}
              value={language}
              onChange={handleChangeLanguage}
            >
              {voiceInfos &&
                Object.keys(voiceInfos).map((key) => (
                  <MenuItem value={key}>{voiceInfos[key].name}</MenuItem>
                ))}
            </Select>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={classes.selectVoice}
              value={voice}
              onChange={handleChangeVoice}
            >
              {dataVoices &&
                dataVoices.map((item) => (
                  <MenuItem value={item.value}>{item.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Dialog
        className={classes.dialogSpeed}
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal,
        }}
        open={currentIndex !== null}
        keepMounted
        onClose={() => {
          setCurrentIndex(null);
          setSpeedInSlider(null);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h3>{t('speed')}</h3>
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setCurrentIndex(null)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          <p>{t('incressSpeed')}</p>
          <div className={classes.sliderSpeed}>
            <Slider
              value={speedInSlider}
              onChange={handleSliderChange}
              aria-labelledby="range-slider"
              step={0.05}
              min={0}
              max={2.0}
            />
            <span className={classes.textSpeed}>{speedInSlider}</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={classes.btnAccept} onClick={onAcceptRateVoice}>
            {t('accept')}
          </Button>
        </DialogActions>
      </Dialog>
      <audio
        ref={audioRef}
        src={srcAudio}
        hidden
        onEnded={onEndedAudio}
        onPause={() => {
          setPlayingConvert(false);
        }}
        onPlaying={() => {
          setPlayingConvert(true);
        }}
      ></audio>
      {alert}
    </div>
  );
}
