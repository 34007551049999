import generateResourcePath from '../services/generateResourcePath';

const IMAGES = {
  logo: generateResourcePath('/images/logo.png'),
  shutterstock: generateResourcePath('/images/shutterstock.jpeg'),
  facebookIcon: generateResourcePath('/images/facebook-icon.svg'),
  gmailIcon: generateResourcePath('/images/gmail-icon.svg'),
  bgInfoPerson: generateResourcePath('/images/bgInfoPerson.png'),
  iconVideo: generateResourcePath('/images/iconVideo.png'),
  iconTimeAccount: generateResourcePath('/images/iconTimeAccount.png'),
  iconUsedVideo: generateResourcePath('/images/iconUsedVideo.png'),
  iconDownload: generateResourcePath('/images/iconDownload.png'),
  iconShare: generateResourcePath('/images/iconShare.png'),
  iconEditor: generateResourcePath('/images/iconEditor.png'),
  infinityIcon: generateResourcePath('/images/infinity-icon.svg'),
  playIcon: generateResourcePath('/images/play-icon.svg'),
  backgroundMc: generateResourcePath('/images/backgroundMc.jpeg'),
  backgroundKol: generateResourcePath('/images/BackgroundKol.jpeg'),
  backgroundMc2: generateResourcePath('/images/backgroundMc2.jpeg'),
  bannerHome: generateResourcePath('/images/bannerHome.jpeg'),
  clipNightMode: generateResourcePath('/images/clipNightMode.jpeg'),
  connectAi: generateResourcePath('/images/connectAi.jpeg'),
  guideCreate: generateResourcePath('/images/guideCreate.jpeg'),
  guideMc: generateResourcePath('/images/guideMc.jpeg'),
  guideTemplate: generateResourcePath('/images/guideTemplate.jpeg'),
  img1: generateResourcePath('/images/img1.jpeg'),
  img2: generateResourcePath('/images/img2.jpeg'),
  imgBackground: generateResourcePath('/images/imgBackground.jpeg'),
  imgMcFooter: generateResourcePath('/images/imgMcFooter.jpeg'),
  imgPrice: generateResourcePath('/images/imgPrice.jpeg'),
  pewPew: generateResourcePath('/images/pewPew.png'),
  logoAiChuTrang: generateResourcePath('/images/logoAiChuTrang.png'),
  makeVideo: generateResourcePath('/images/makeVideo.jpeg'),
  qrCode: generateResourcePath('/images/qrCode.png'),
  question: generateResourcePath('/images/questionIcon.svg'),
  star: generateResourcePath('/images/star.jpeg'),
  typeMc: generateResourcePath('/images/typeMc.jpeg'),
  user: generateResourcePath('/images/userIcon.svg'),
  Vector: generateResourcePath('/images/Vector.png'),
  viettel: generateResourcePath('/images/viettel.jpg'),
  mobifone: generateResourcePath('/images/mobifone.png'),
  tuoitre: generateResourcePath('/images/tuoitre.png'),
  nguoiduatin: generateResourcePath('/images/nguoiduatin.png'),
  ttttThaiBinh: generateResourcePath('/images/4tThaiBinh.jpg'),
  ttttHaTinh: generateResourcePath('/images/4tHaTinh.png'),
  tttBinhDinh: generateResourcePath('/images/4tBinhDinh.png'),
  quangNgai: generateResourcePath('/images/quangNgai.jpg'),
  tttGiaLai: generateResourcePath('/images/4tGiaLai.png'),
  ttttPhuYen: generateResourcePath('/images/4tPhuYen.png'),
  tckd: generateResourcePath('/images/tckd.jpg'),
  ptthLaoCai: generateResourcePath('/images/ptthLaoCai.jpg'),
  ttttThaiNguyen: generateResourcePath('/images/4tThaiNguyen.png'),
  vtv: generateResourcePath('/images/vtv.png'),
  vtvMc: generateResourcePath('/images/vtvMc.png'),
  menuIcon: generateResourcePath('/images/menuIcon.svg'),
  house: generateResourcePath('/images/houseIcon.svg'),
  prevIcon: generateResourcePath('/images/prev-icon.svg'),
  nextIcon: generateResourcePath('/images/next-icon.svg'),
  nightMode: generateResourcePath('/images/nightModeIcon.svg'),
  avartaUser: generateResourcePath('/images/avartaUser.png'),
  videoContent: generateResourcePath('/images/videoContent.jpeg'),
  typeVideo: generateResourcePath('/images/typeVideo.jpeg'),
  selectMc: generateResourcePath('/images/selectMc.jpeg'),
  backgroundS2: generateResourcePath('/images/backgroundS2.png'),
  backgroundContent: generateResourcePath('/images/backgroundContent.png'),
  imgBackGroundS2: generateResourcePath('/images/imgBackGroundS2.jpeg'),
  createVideoIcon: generateResourcePath('/images/create-video-icon.svg'),
  womanIcon: generateResourcePath('/images/woman-icon.svg'),
  onlineLearningIcon: generateResourcePath('/images/online-learning-icon.svg'),
  deleteVideoIcon: generateResourcePath('/images/delete-video-icon.svg'),
  addSceneIcon: generateResourcePath('/images/add-scene-icon.svg'),
  shareIcon: generateResourcePath('/images/share-icon.svg'),
  uploadIcon: generateResourcePath('/images/upload-icon.svg'),
  playArrowIcon: generateResourcePath('/images/play-arrow-icon.svg'),
  sliderPrevIcon: generateResourcePath('/images/slider-prev-icon.svg'),
  sliderNextIcon: generateResourcePath('/images/slider-next-icon.svg'),
  greenScreen: generateResourcePath('/images/greenScreen.png'),
  mcInVideo: generateResourcePath('/images/mcInVideo.png'),
  iconNextScreen: generateResourcePath('/images/iconNext.png'),
  iconSpeed: generateResourcePath('/images/iconSpeed.png'),
  iconLoadPlay: generateResourcePath('/images/iconLoad.gif'),
  share: generateResourcePath('/images/share.png'),
  mcIcon: generateResourcePath('/images/mc-icon.svg'),
  activeMcIcon: generateResourcePath('/images/active-mc-icon.svg'),
  templateIcon: generateResourcePath('/images/template-icon.svg'),
  activeTemplateIcon: generateResourcePath('/images/active-template-icon.svg'),
  multimediaIcon: generateResourcePath('/images/multimedia-icon.svg'),
  iconLoading: generateResourcePath('/images/iconLoading.gif'),
  activeMultimediaIcon: generateResourcePath(
    '/images/active-multimedia-icon.svg',
  ),
  googleIcon: generateResourcePath('/images/google-icon.svg'),
  iconEmail: generateResourcePath('/images/iconEmail.png'),
  iconTel: generateResourcePath('/images/iconTel.png'),
  bgYoutube: generateResourcePath('/images/bgYoutube.png'),
  announced: generateResourcePath('/images/dathongbao.png'),
};

export default IMAGES;
