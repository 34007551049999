import { makeStyles } from '@material-ui/core';
import { container } from 'assets/jss/material-dashboard-pro-react';
import { IMAGES } from '../../constants';

export default makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${IMAGES.shutterstock})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  container: {
    ...container,
  },
  card: {
    background: 'rgba(255,255,255,0.8)',
  },
  cardBody: {
    [theme.breakpoints.up('md')]: {
      padding: '40px 60px',
    },
  },
  logo: {
    height: '74px',
    [theme.breakpoints.down('xs')]: {
      height: '48px',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '42px',
    letterSpacing: '-0.3px',
    color: '#808080',
    marginTop: '58px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
      marginTop: '28px',
      marginBottom: '20px',
    },
  },
  text: {
    fontSize: '18px',
    marginTop: '0px',
    marginBottom: '17px',
    letterSpacing: '-0.3px',
    color: '#808080',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      marginBottom: '12px',
    },
  },
  textAuto: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  textRegister: {
    fontStyle: 'italic',
  },
  linkWrapper: {
    marginTop: '60px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px',
    },
  },
  link: {
    color: '#3157A7',
    fontSize: '18px',
    '&:hover,&:focus': {
      color: '#3157A7',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  right: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '36px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '24px',
    },
  },
  customFormControlClasses: {
    paddingTop: '0px !important',
    marginBottom: '18px !important',
  },
  input: {
    height: '44px',
    borderRadius: '10px',
  },
  checkboxLabelControl: {
    margin: '0',
    alignItems: 'start',
  },
  checkboxLabel: {
    fontSize: '0.875rem',
    marginLeft: '6px',
  },
  checkRoot: {
    padding: '6px',
    marginLeft: '-6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  checked: {
    color: '#266FB8 !important',
  },
  checkedIcon: {
    width: '14px',
    height: '14px',
    border: '1px solid #C6C6C6',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '6px',
    border: '1px solid #C6C6C6',
    borderRadius: '3px',
  },
  rowButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
    background: 'linear-gradient(90deg, #2CA8E0 0%, #2E4396 100%)',
    padding: '0px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  },
  button: {
    padding: '6px 8px',
    textTransform: 'unset',
    fontWeight: 'bold',
    fontSize: '15px',
    letterSpacing: '-0.3px',
  },
  icons: {
    width: '24px !important',
    height: '24px !important',
  },
}));
