import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Slider,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PauseIcon from '@material-ui/icons/Pause';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import NavPills from 'components/NavPills/NavPills';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import styles from './index.style';
import apis from '../../../../apis';
import { API_DOMAIN } from '../../../../configs';
import { usePrevious } from 'hooks';

const useStyles = makeStyles(styles);

const MainContent = ({ voiceInfo, currentTemplate, handleUpdateTemplate }) => {
  const classes = useStyles();

  const { t } = useTranslation('createTemplate');

  const { userInfo } = useSelector((state) => state.auth);
  const { maxText } = userInfo;

  const inputUploadRef = useRef(null);
  const audioRef = useRef(null);
  const [loadingConvert, setLoadingConvert] = useState(false);
  const [audioLink, setAudioLink] = useState();
  const [playingConvert, setPlayingConvert] = useState(false);

  const { image, listScene, selectedScene } = currentTemplate || {
    listScene: [],
  };

  const previousScene = usePrevious(selectedScene);

  useEffect(() => {
    if (previousScene === undefined) return;
    if (previousScene !== selectedScene) {
      setAudioLink(null);
      setPlayingConvert(false);
      if (audioRef.current !== null) {
        audioRef.current.pause();
      }
    }
  }, [previousScene, selectedScene]);

  const currentScene = useMemo(() => {
    if (listScene.length === 0 || selectedScene === null) return null;
    return listScene[selectedScene];
  }, [listScene, selectedScene]);

  const {
    selectedLang,
    selectedVoice,
    text = '',
    rate = 1.0,
    audio,
    images,
  } = currentScene || { images: [] };

  const languages = useMemo(() => {
    return Object.keys(voiceInfo).map((key) => {
      return {
        value: key,
        name: voiceInfo[key].name,
      };
    });
  }, [voiceInfo]);

  const language = useMemo(() => {
    if (!languages.length) return '';
    return selectedLang;
  }, [languages, selectedLang]);

  const voices = useMemo(() => {
    if (!voiceInfo || !selectedLang) return [];

    const { list = {} } = voiceInfo[selectedLang] || {};
    return Object.keys(list).map((key) => ({
      value: key,
      name: list[key],
    }));
  }, [voiceInfo, selectedLang]);

  const voice = useMemo(() => {
    if (voices.length === 0 || selectedVoice === undefined) return '';
    return voices[selectedVoice].value;
  }, [selectedVoice, voices]);

  const handleChangeLanguage = (event) => {
    if (currentScene === null) return;

    const { value } = event.target;

    const newListScene = [...listScene];
    const newScene = { ...currentScene, selectedLang: value, selectedVoice: 0 };
    newListScene.splice(selectedScene, 1, newScene);
    handleUpdateTemplate({
      ...currentTemplate,
      listScene: newListScene,
    });
  };

  const handleChangeVoice = (event) => {
    if (currentScene === null) return;

    const { value } = event.target;
    const index = voices.findIndex((ele) => ele.value === value);

    const newListScene = [...listScene];
    const newScene = { ...currentScene, selectedVoice: index };
    newListScene.splice(selectedScene, 1, newScene);
    handleUpdateTemplate({
      ...currentTemplate,
      listScene: newListScene,
    });
  };

  const handleTextChange = (event) => {
    if (currentScene === null) return;
    setAudioLink(null);

    const { value } = event.target;

    const newListScene = [...listScene];
    const newScene = { ...currentScene, text: value };
    newListScene.splice(selectedScene, 1, newScene);
    handleUpdateTemplate({
      ...currentTemplate,
      listScene: newListScene,
    });
  };

  const handleRateChange = (event, newValue) => {
    if (currentScene === null) return;
    setAudioLink(null);

    const newListScene = [...listScene];
    const newScene = { ...currentScene, rate: newValue };
    newListScene.splice(selectedScene, 1, newScene);
    handleUpdateTemplate({
      ...currentTemplate,
      listScene: newListScene,
    });
  };

  const handlePlayConvertText = async () => {
    if (audioLink) {
      if (audioRef.current === null) return;

      if (playingConvert) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      return;
    }

    setLoadingConvert(true);
    try {
      const res = await apis.voice.convertTTS({
        inputText: text,
        voice,
        rate,
      });
      const { error, link } = res;
      if (error === 0) {
        setAudioLink(link);
        if (audioRef.current !== null) {
          audioRef.current.pause();
          audioRef.current.load();
          audioRef.current.play();
        }
      }
    } catch (_) {}
    setLoadingConvert(false);
  };

  const handleAudioChange = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    if (file) {
      if (currentScene === null) return;
      const newListScene = [...listScene];
      const newScene = { ...currentScene, audio: file };
      newListScene.splice(selectedScene, 1, newScene);
      handleUpdateTemplate({
        ...currentTemplate,
        listScene: newListScene,
      });
    }
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({ file, imagePreviewUrl: reader.result });
      };
      reader.onerror = () => {
        reject(reader);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e) => {
    e.preventDefault();
    const { files } = e.target;
    if (!files.length) return;

    try {
      const readers = [];
      for (let i = 0; i < files.length; i++) {
        readers.push(readFileAsDataURL(files[i]));
      }

      let newImages = await Promise.all(readers);
      inputUploadRef.current.value = null;

      newImages = [...newImages, ...images];
      const newListScene = [...listScene];
      const newScene = { ...currentScene, images: newImages };
      newListScene.splice(selectedScene, 1, newScene);
      handleUpdateTemplate({
        ...currentTemplate,
        listScene: newListScene,
      });
    } catch (error) {}
  };

  const handleRemoteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    const newListScene = [...listScene];
    const newScene = { ...currentScene, images: newImages };
    newListScene.splice(selectedScene, 1, newScene);
    handleUpdateTemplate({
      ...currentTemplate,
      listScene: newListScene,
    });
  };

  const renderPlayIcon = () => {
    if (loadingConvert) {
      return <CircularProgress className={classes.loadingIcon} />;
    }
    if (playingConvert) {
      return <PauseIcon />;
    }
    return <PlayArrowIcon />;
  };

  if (!currentScene) return <div />;

  return (
    <div className={classes.container}>
      <div>
        <img
          className={classes.fullImage}
          src={
            image
              ? `${API_DOMAIN}${image}`
              : require('assets/img/register.jpeg')
          }
        ></img>
      </div>
      <NavPills
        tabs={[
          {
            tabButton: t('tabScript'),
            tabContent: (
              <div className={classes.tabContent}>
                <Typography variant="h6" className={classes.textWarning}>
                  {t('scriptWarning')}
                </Typography>
                <FormControl fullWidth className={classes.formControl}>
                  <TextareaAutosize
                    className={classes.textareaContent}
                    rowsMin={10}
                    rowsMax={10}
                    placeholder={t('scriptPlaceholder')}
                    value={text}
                    onChange={handleTextChange}
                    maxLength={maxText}
                  />
                </FormControl>
                <div className={classes.maxLength}>
                  {text.length}/{maxText}
                </div>
              </div>
            ),
          },
          {
            tabButton: t('tabAudio'),
            tabContent: (
              <div className={classes.tabContent}>
                <div className={classes.uploadContainer}>
                  <div className={classes.audioContainer}>
                    <CloudUploadIcon />
                    {audio && (
                      <p>
                        {t('chosen')} <span>{audio.name}</span>
                      </p>
                    )}
                    <p>
                      {t('uploadAudio')} <span>(.mp3, .wav)</span>
                    </p>
                    <input
                      className={classes.fileInput}
                      type="file"
                      accept=".mp3,.wav"
                      onChange={(e) => handleAudioChange(e)}
                    />
                  </div>
                  <p>
                    {t('audioWarning1')} <br /> <b>{t('audioWarning2')}</b>
                  </p>
                </div>
              </div>
            ),
          },
          {
            tabButton: t('tabImage'),
            tabContent: (
              <div className={classes.tabContent}>
                <Typography variant="h6" className={classes.textWarning}>
                  {t('imageWarning')}
                </Typography>

                <GridContainer>
                  {images.map((item, index) => {
                    const { imagePreviewUrl } = item;

                    return (
                      <GridItem
                        xs={4}
                        key={index}
                        className={classes.gridItemImage}
                      >
                        <div className={classes.imageContainer}>
                          <img src={imagePreviewUrl} alt="" />
                          <Button
                            className={classes.delButton}
                            onClick={() => handleRemoteImage(index)}
                          >
                            <HighlightOffIcon />
                          </Button>
                        </div>
                      </GridItem>
                    );
                  })}

                  <GridItem xs={4} className={classes.gridItemImage}>
                    <div className={classes.uploadImageContainer}>
                      <BurstModeIcon />
                      <p>{t('uploadImage')}</p>
                      <input
                        ref={inputUploadRef}
                        className={classes.fileInput}
                        type="file"
                        accept=".jpg,.png"
                        onChange={(e) => handleImageChange(e)}
                        multiple
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            ),
          },
        ]}
      />
      <div className={classes.videoOptions}>
        <div className={classes.selectContainer}>
          <FormControl
            className={cx(classes.selectFormControl, classes.selectLangControl)}
          >
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={language}
              onChange={handleChangeLanguage}
              inputProps={{
                name: 'language',
                id: 'language-select',
              }}
              disableUnderline
            >
              {languages.map((lang, index) => {
                const { value, name } = lang;
                return (
                  <MenuItem
                    key={index}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={value}
                  >
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            className={cx(
              classes.selectFormControl,
              classes.selectVoiceControl,
            )}
          >
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={voice}
              onChange={handleChangeVoice}
              inputProps={{
                name: 'voice',
                id: 'voice-select',
              }}
              disableUnderline
            >
              {voices.map((voice, index) => {
                const { value, name } = voice;
                return (
                  <MenuItem
                    key={index}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={value}
                  >
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className={classes.selectRate}>
          <FormLabel className={classes.labelHorizontal}>
            {t('readingSpeed')}
          </FormLabel>
          <Slider
            className={classes.slider}
            aria-labelledby="continuous-slider"
            valueLabelDisplay="auto"
            value={rate}
            min={0.1}
            max={2.0}
            step={0.05}
            onChange={handleRateChange}
          />
        </div>
        <Button
          className={classes.primaryButton}
          startIcon={renderPlayIcon()}
          onClick={handlePlayConvertText}
        >
          {t('tryListening')}
        </Button>
        <audio
          ref={audioRef}
          src={audioLink}
          hidden
          onEnded={() => {
            setPlayingConvert(false);
          }}
          onPause={() => {
            setPlayingConvert(false);
          }}
          onPlaying={() => {
            setPlayingConvert(true);
          }}
        ></audio>
      </div>
    </div>
  );
};

export default MainContent;

MainContent.propTypes = {
  currentTemplate: PropTypes.object,
  handleUpdateTemplate: PropTypes.func,
  voiceInfo: PropTypes.object,
};
