import { askForPermissionToReceiveNotifications } from '../../push-notification';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import actions from '../../redux/actions';

import AuthNavbar from '../../components/Navbars/AuthNavbar';
import ScrollToTop from '../../components/ScrollToTop';
import Content from './Content';
import Footer from './Footer';
import StyledLayout from './index.style';

const GuestLayout = ({ children }) => {
  const { userInfo, firebaseToken, updateFirebaseTokenError } = useSelector(
    (state) => state.auth,
  );
  const { id: userId } = userInfo || {};

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setFirebaseToken = async () => {
    const currFirebaseToken = await askForPermissionToReceiveNotifications();
    if (currFirebaseToken && currFirebaseToken !== firebaseToken) {
      dispatch(
        actions.auth.updateMemberFireBaseToken(userId, currFirebaseToken),
      );
    }
  };

  useEffect(() => {
    setFirebaseToken();
  }, []);

  // useEffect(() => {
  //   if (updateFirebaseTokenError) {
  //     alert(t('updateFirebaseTokenError'));
  //   }
  // }, [updateFirebaseTokenError]);
  return (
    <ScrollToTop>
      <StyledLayout>
        <AuthNavbar />
        <Content>{children}</Content>
        <Footer />
      </StyledLayout>
    </ScrollToTop>
  );
};

export default GuestLayout;
