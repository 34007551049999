import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';

import ListItemIcon from './ListItemIcon';
import useStyles from './index.style';
import { ROUTES } from 'constants/index';

const iconFillColors = {
  free: '#C4C4C4',
  save: '#2091F9',
  experience: '#FFFFFF',
  recommend: '#2091F9',
  pro: '#2091F9',
  enterprise: '#3157A7',
};

const PricingItem = ({
  type,
  title,
  description,
  price,
  sale,
  features,
  packageId,
}) => {
  const classes = useStyles();

  const { t } = useTranslation('pricing');
  const history = useHistory();

  const cardClasses = classNames({
    [classes.card]: true,
    [classes.experienceCard]: type === 'experience',
  });

  const titleClasses = classNames({
    [classes.title]: true,
    [classes[type + 'TextColor']]: type,
    [classes[type + 'TextColorExperience']]: type,
  });

  const descriptionClasses = classNames({
    [classes.description]: true,
    [classes.whiteTextColor]: type === 'experience',
  });

  const dividerClasses = classNames({
    [classes.divider]: true,
    [classes.experienceDivider]: type === 'experience',
  });

  const priceClasses = classNames({
    [classes.price]: true,
    [classes[type + 'TextColor']]: type && type !== 'free',
    [classes.priceFontSmall]: !['free'].includes(type),
  });

  const oldPriceClasses = classNames({
    [classes.oldPrice]: true,
    [classes[type + 'TextColor']]: type && type !== 'free',
  });

  const currencyClasses = classNames({
    [classes.currency]: true,
    [classes.whiteTextColor]: type === 'experience',
  });

  const featureClasses = classNames({
    [classes.feature]: true,
    [classes.whiteTextColor]: type === 'experience',
  });

  const buttonClasses = classNames({
    [classes.btnBuy]: true,
    [classes[type + 'Button']]: type,
  });

  const handleShowPayment = () => {
    history.push(`${ROUTES.PAYMENT}/${packageId}`);
  };

  return (
    <Card pricing className={cardClasses}>
      <CardBody className={classes.cardBody}>
        <h4 className={titleClasses}>{title}</h4>
        <h6 className={descriptionClasses}>{description}</h6>
        <div className={dividerClasses} />
        <div>
          {sale !== '' ? (
            <>
              <p className={oldPriceClasses}>{price}</p>
              <h4 className={priceClasses}>
                <span>{sale}</span>
              </h4>
            </>
          ) : (
            <h4 className={priceClasses}>
              <span>{price}</span>
            </h4>
          )}

          <h4 className={currencyClasses}>VNĐ/Tháng</h4>
        </div>
        <ul>
          {features.map((ele, index) => (
            <li key={index}>
              <div className={classes.listStyle}>
                <ListItemIcon fill={iconFillColors[type] || '#C4C4C4'} />
              </div>
              <p className={featureClasses}>{ele} </p>
            </li>
          ))}
        </ul>
      </CardBody>
      <CardFooter className={classes.justifyContentCenter}>
        <Button
          className={buttonClasses}
          onClick={handleShowPayment}
          disabled={type === 'free'}
        >
          {t('buyNow')}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default PricingItem;

PricingItem.defaultProps = {
  type: 'free',
  display: 'vnd',
};

PricingItem.propTypes = {
  type: PropTypes.oneOf([
    'free',
    'save',
    'experience',
    'recommend',
    'pro',
    'enterprise',
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  sale: PropTypes.string,
  features: PropTypes.array.isRequired,
  display: PropTypes.oneOf(['vnd', 'usd']),
  packageId: PropTypes.number,
};
