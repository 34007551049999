import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

const ListItemIcon = ({ fill, ...rest }) => {
  return (
    <SvgIcon {...rest}>
      <rect
        x="5.07104"
        y="0.000488281"
        width="2.86862"
        height="2.86862"
        transform="rotate(45 5.07104 0.000488281)"
        fill={fill}
      />
      <rect
        x="8.11377"
        y="3.0426"
        width="2.86862"
        height="2.86862"
        transform="rotate(45 8.11377 3.0426)"
        fill={fill}
      />
      <rect
        x="2.02832"
        y="3.0426"
        width="2.86862"
        height="2.86862"
        transform="rotate(45 2.02832 3.0426)"
        fill={fill}
      />
      <rect
        x="5.07104"
        y="6.08521"
        width="2.86862"
        height="2.86862"
        transform="rotate(45 5.07104 6.08521)"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default ListItemIcon;

ListItemIcon.defaultProps = {
  fill: '#2091F9',
};

ListItemIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
};
