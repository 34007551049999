import {
  primaryColor,
  grayColor,
  whiteColor,
} from 'assets/jss/material-dashboard-pro-react.js';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const asidebarStyle = (theme) => ({
  ...sweetAlertStyle,
  container: {
    backgroundColor: '#F5F5F5',
  },
  headerStep: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: '10px',
    marginTop: '10px',
    color: '#3157A7',
  },
  avatarImg: {
    width: '100%',
    verticalAlign: 'middle',
    borderRadius: '50%',
    padding: '2px',
    cursor: 'pointer',
  },
  avatarShapeImg: {
    width: '100%',
    verticalAlign: 'middle',
    marginTop: '5px',
    padding: '2px',
    '&:hover': {
      border: `1px solid ${primaryColor[0]}`,
    },
  },
  carouselImg: {
    width: '100%',
    objectFit: 'cover',
    backgroundSize: 'cover',
    padding: '5px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  sliderItem: {
    borderRadius: '5px',
    padding: '5px',
  },
  itemSelected: {
    boxSizing: 'border-box',
    border: `1px solid ${whiteColor}`,
    boxShadow: '0px 0px 8px #81BCFF',
  },
  nameMC: {
    marginTop: '10px',
    textAlign: 'center',
  },
  iconCheck: {
    maxWidth: '8px',
  },
  flexContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  displayNone: {
    display: 'none !important',
  },
  fixed: {
    overflow: 'visible !important',
  },
  horizontalDisplay: {
    display: 'block',
  },
  root: {
    marginTop: '0',
    paddingLeft: '0',
    marginBottom: '0',
    marginRight: '-30px',
    overflow: 'visible !important',
    backgroundColor: '#D7D7D7',
    height: 'calc(100vh - 110px)',
    borderRadius: '20px 0px 0px 20px',
  },
  pills: {
    float: 'left',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    color: grayColor[6],
    height: 'auto',
    opacity: '1',
    fontSize: '12px',
    maxWidth: '100%',
    minWidth: '100px',
    minHeight: '48px',
    textAlign: 'center',
    transition: 'all .3s',
    fontWeight: '500',
    lineHeight: '24px',
    textTransform: 'uppercase',
    letterSpacing: 'initial',
  },
  pillsSelected: {
    backgroundColor: '#E8E8E8',
  },
  pillsBorderTopLeft: {
    borderTopLeftRadius: '20px',
  },
  pillsWithIcons: {
    borderRadius: '4px',
  },
  tabIcon: {
    width: '30px',
    height: '30px',
    display: 'block',
    margin: '15px 0 !important',
  },
  horizontalPills: {
    width: '100%',
    float: 'none !important',
  },
  bage: {
    '& .MuiBadge-colorPrimary': {
      backgroundColor: '#81BCFF',
    },
  },
  closeItem: {
    padding: '15px !important',
  },
  tabContent: {
    maxHeight: 'calc(100vh - 123px)',
    overflowY: 'scroll',
  },
  listTemplates: {
    margin: '10px 0px',
  },
  templateContainer: {
    padding: '20px 0px',
  },
  templateImg: {
    maxWidth: 'calc(100% - 10px)',
    borderRadius: '10px',
    padding: '5px',
    margin: '0px 5px',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > img': {
      maxWidth: '203px',
    },
    '& > .MuiTypography-root': {
      marginTop: '12px',
      marginBottom: '20px',
    },
  },
  buttonWidthPadding: {
    width: '100%',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  buttonMaxWidth: {
    width: '100%',
  },
  primaryButton: {
    color: whiteColor,
    width: '100%',
    padding: '15px',
    '&,&:hover,&:focus': {
      backgroundColor: primaryColor[0],
    },
  },
  greyButton: {
    color: whiteColor,
    width: '100%',
    padding: '15px',
    marginBottom: '29px',
    '&,&:hover,&:focus': {
      backgroundColor: '#636363',
    },
  },
  customFormControlClasses: {
    paddingTop: '0px !important',
    marginBottom: '34px !important',
    '&:first-child': {
      marginTop: '53px',
    },
  },
  draftButton: {
    padding: '28px 0px',
  },
});

export default asidebarStyle;
