import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, TablePagination } from '@material-ui/core';

import Table from 'components/Table/Table';
import TablePaginationActions from 'components/Table/TablePaginationActions';

import useStyles from './index.style';
import apis from 'apis';
import { formatNumber } from 'utils/number';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ORDER_STATUS } from '../../../constants';

const OrderForm = ({ isActive, status, getAlert }) => {
  const { t } = useTranslation('order');
  const classes = useStyles();
  const [alert, setAlert] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [actionStatus, setActionStatus] = useState();

  const handleGetListOrder = async (params) => {
    try {
      const data = await apis.order.getListOrders({
        page,
        limit: rowsPerPage,
        ...params,
      });
      if (data.error === 0) {
        setTotal(data.total);
        setOrderData(data.list);
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (!isActive || orderData) return;
    handleGetListOrder({ status: status, page: page, limit: rowsPerPage });
  }, [isActive, orderData]);

  useEffect(() => {
    if (getAlert) getAlert(alert);
  }, [alert]);

  const hideAlert = () => {
    setAlert(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    handleGetListOrder({ status: status, page: newPage, limit: rowsPerPage });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    handleGetListOrder({
      status: status,
      page: 0,
      limit: parseInt(event.target.value, 10),
    });
  };

  const handeConfirmOrder = async (item) => {
    setAlert(null);
    try {
      const data = await apis.order.updateOrders({
        id: item.id,
        status: ORDER_STATUS.SUCCESS,
      });
      if (data.error === 0) {
        handleGetListOrder({
          status: status,
          page: 0,
          limit: rowsPerPage,
        });
      }
    } catch (_) { }
  };

  const handeConfirmCancel = async (item) => {
    setAlert(null);
    try {
      const data = await apis.order.updateOrders({
        id: item.id,
        status: ORDER_STATUS.ERROR,
      });
      if (data.error === 0) {
        handleGetListOrder({ status: status, page: 0, limit: rowsPerPage });
      }
    } catch (_) { }
  };

  const handelAcceptOrder = (item) => {
    setActionStatus(ORDER_STATUS.SUCCESS);
    setAlert(
      <SweetAlert
        warning
        showCancel
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('notification')}
        onConfirm={() => handeConfirmOrder(item)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('accept')}
        cancelBtnText={t('close')}
      >
        <p>
          {t('confirmPayment')} {item.id} ?
        </p>
      </SweetAlert>,
    );
    return;
  };

  const handelCancelOrder = (item) => {
    setActionStatus(ORDER_STATUS.ERROR);
    setAlert(
      <SweetAlert
        warning
        showCancel
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('notification')}
        onConfirm={() => handeConfirmCancel(item)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('accept')}
        cancelBtnText={t('close')}
      >
        <p>
          {t('cancelPayment')} {item.id}
        </p>
      </SweetAlert>,
    );
    return;
  };

  const tableData = () => {
    if (orderData) {
      return orderData.map((item, index) => {
        return [
          index + 1,
          item.id,
          item.account ? item.account.username : ' ',
          item.package ? item.package.title : '',
          item.package ? formatNumber(item.package.price) : '',
          item.package ? formatNumber(item.package.sale) : '',
          <div>
            {status === ORDER_STATUS.WAITING && (
              <>
                <Button
                  className={classes.btnAccept}
                  onClick={() => {
                    setActionStatus(ORDER_STATUS.WAITING);
                    handelAcceptOrder(item);
                  }}
                >
                  {t('confirmBowser')}
                </Button>
                <Button
                  className={classes.btnCancel}
                  onClick={() => {
                    setActionStatus(ORDER_STATUS.ERROR);
                    handelCancelOrder(item);
                  }}
                >
                  {t('cancel')}
                </Button>
              </>
            )}
            {status === ORDER_STATUS.SUCCESS && <p>{t('approved')}</p>}
            {status === ORDER_STATUS.ERROR && <p>{t('canceled')}</p>}
          </div>,
        ];
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    tableData();
  }, [orderData, actionStatus]);

  return (
    <div>
      <Table
        tableHead={[
          'STT',
          'ID',
          t('account'),
          t('packageName'),
          t('price'),
          t('priceSale'),
          t('browsingStatus'),
        ]}
        tableData={tableData()}
        customCellClasses={[
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
        ]}
        customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
        customHeadCellClasses={[
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
          classes.center,
        ]}
        customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
      />
      <TablePagination
        className={classes.tablePagination}
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
};

export default OrderForm;
