import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import InputLabel from '@material-ui/core/InputLabel';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';

import ButtonBase from '@material-ui/core/ButtonBase';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckIcon from '@material-ui/icons/Check';

import useStyles from './index.style';
import IMAGES from '../../../constants/images';
import apis from 'apis';
import { ROUTES } from '../../../constants';
import { PUBLIC_URL } from '../../../configs';

export default function Sidebar({ listData, linkAudioImage }) {
  const classes = useStyles();
  const { t } = useTranslation(['greenScreen']);
  const history = useHistory();
  const videoRef = useRef();
  const { userInfo } = useSelector((state) => state.auth);
  const { maxText } = userInfo;

  const [isPolicy, setIsPolicy] = useState(false);
  const [alert, setAlert] = useState(null);
  const [disableCreate, setDisableCreate] = useState(false);
  const [visiable, setVisiable] = useState(false);

  const hideAlert = () => {
    setAlert(null);
    setVisiable(false);
  };

  const createdVideo = async () => {
    setDisableCreate(true);
    setVisiable(false);
    let params = {};

    if (listData.valueFileAudio) {
      params = {
        title: listData.valueTitle,
        image: linkAudioImage.linkImage ? linkAudioImage.linkImage : '',
        path_resource: linkAudioImage.linkAudio ? linkAudioImage.linkAudio : '',
        path_audio: listData.valueFileAudio,
        voice: listData.voice,
        account_id: userInfo.id,
        category_id: 1,
        is_private: 0,
      };
    } else {
      params = {
        title: listData.valueTitle,
        image: linkAudioImage.linkImage ? linkAudioImage.linkImage : '',
        path_resource: linkAudioImage.linkAudio ? linkAudioImage.linkAudio : '',
        text: JSON.stringify(listData.listSentence),
        voice: listData.voice,
        account_id: userInfo.id,
        category_id: 1,
        is_private: 0,
      };
    }

    try {
      const res = await apis.video.createdVideo(params);
      if (res.error === 0) {
        setVisiable(false);
        history.push(`${ROUTES.VIDEOS}`);
      } else {
        setAlert(
          <SweetAlert
            danger
            style={{ display: 'block', marginTop: '-100px' }}
            title={t('notification')}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
          >
            {/* {t('serverError')} */}
            {res.errorMsg}
          </SweetAlert>,
        );
      }
    } catch (error) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
        >
          {t('serverError')}
        </SweetAlert>,
      );
    }
    setDisableCreate(false);
  };

  const warring = () => {
    if (!listData.valueTitle) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText={t('close')}
        >
          {t('enterTitle')}
        </SweetAlert>,
      );
      return;
    }
    if (!listData.valueFileAudio && listData.listSentence[0].text === '') {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText={t('close')}
        >
          {t('enterContent')}
        </SweetAlert>,
      );
      return;
    }
    if (!isPolicy) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText={t('close')}
        >
          <p>
            {t('needAgreeWith')}
            <Link to={ROUTES.POLICY} target="_blank">
              {t('policy')}
            </Link>{' '}
            {t('ofAiClip')}
          </p>
        </SweetAlert>,
      );
      return;
    }
    let totalLength = 0;
    listData.listSentence.map((item) => {
      totalLength += item.text.length;
    });
    if (totalLength > maxText) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText={t('close')}
        >
          <p>{t('maxText')}</p>
        </SweetAlert>,
      );
      return;
    }
    setVisiable(true);
  };

  const linkAudio = useMemo(() => {
    if (linkAudioImage !== null) {
      if (videoRef.current) videoRef.current.load();
      return linkAudioImage.linkAudioDemo;
    }
  }, [linkAudioImage.linkAudioDemo]);

  return (
    <div className={classes.siderbar}>
      <a href={ROUTES.VIDEOS} className={classes.linkMyVideo}>
        <ArrowBackIosIcon />
        {t('myVideo')}
      </a>
      {linkAudioImage.linkAudioDemo && (
        <div className={classes.videoGroup}>
          <video controls autoPlay ref={videoRef}>
            <source src={linkAudio} type="video/mp4" />
          </video>
        </div>
      )}
      <div className={classes.boxPolicy}>
        <FormControlLabel
          classes={{
            root: classes.checkboxLabelControl,
            label: classes.checkboxLabel,
          }}
          control={
            <Checkbox
              tabIndex={-1}
              checkedIcon={<CheckIcon className={classes.checkedIcon} />}
              icon={<CheckIcon className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
              value={isPolicy}
              onChange={(event, value) => {
                setIsPolicy(value);
              }}
            />
          }
          label={
            <span className={classes.termsAndCondition}>
              {t('agree')}{' '}
              <Link
                to={`${PUBLIC_URL}/DIEU_KHOAN_SU_DUNG_AICLIP.pdf`}
                target="_blank"
              >
                {t('policy')}
              </Link>{' '}
              {t('ofAiClip')}
            </span>
          }
        />
        <div className={classes.boxCreateLoading}>
          <ButtonBase
            variant="contained"
            children={
              <>
                <img src={IMAGES.share} alt={'share'} />
                <InputLabel>{t('publish')}</InputLabel>
              </>
            }
            className={classes.btnAddScene}
            onClick={() => {
              warring();
            }}
          />
          {disableCreate && (
            <div className={classes.imgLoading}>
              <img src={`${IMAGES.iconLoading}`} alt="" />
            </div>
          )}
        </div>
      </div>
      {alert}
      {visiable && (
        <SweetAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('warring')}
          onConfirm={() => {
            setDisableCreate(true);
            createdVideo();
          }}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText={t('iGetIt')}
          disabled={disableCreate}
          closeOnClickOutside={true}
        >
          <p> {t('warringContent')}</p>
        </SweetAlert>
      )}
    </div>
  );
}
