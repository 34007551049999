import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useParams, useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import apis from '../../apis';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

// import LockOutline from "@material-ui/icons/LockOutline";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import PaymentTransfer from 'components/Payment/PaymentTransfer';
import PaymentATM from 'components/Payment/PaymentATM';
import PaymentVISA from 'components/Payment/PaymentVISA';
import PaymentPaypal from 'components/Payment/PaymentPaypal';

import styles from 'assets/jss/material-dashboard-pro-react/views/paymentPageStyle';
import shutterstock from 'assets/img/shutterstock.jpeg';
import { PAYMENT_METHOD, ROUTES } from '../../constants';
import { formatNumber } from '../../utils/number';
import actions from '../../redux/actions';

const useStyles = makeStyles(styles);

const mapPricingTypes = {
  'Miễn Phí': 'free10time',
  'Tiết Kiệm': 'recommendPackage',
  'Trải Nghiệm': 'experiencePackage',
  'Khuyên Dùng': 'recommendPackage',
  Pro: 'recommendPackage',
  'Doanh Nghiệp': 'enterprisePackage',
};

const Payment = () => {
  const [methodPayment, setMethodPayment] = useState('');
  const [selectedBankTransfer, setSelectedBankTransfer] = useState('');
  const [bankInfoTransfer, setBankInfoTransfer] = useState(null);
  const [packageSelect, setPackageSelect] = useState({});
  const [methodPayemtMessage, setMethodPaymentMessage] = useState('');
  const [selectBankMessage, setSelectBankMessage] = useState('');
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [duration, setDuration] = useState(600);
  const [internalId, setInternalId] = useState(null);
  const [buyTime, setBuyTime] = useState(new Date());
  // alert
  const [alert, setAlert] = useState(null);
  const { t } = useTranslation(['payment']);
  const [isPaid, setIsPaid] = useState(false);
  const { packages } = useSelector((state) => state.payment);
  const params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);
  const { phone } = userInfo;

  const hideAlert = () => {
    setAlert(null);
  };

  const handleShowAlert = (message) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: 'block', marginTop: '-100px' }}
        title={message}
        confirmBtnText={t('confirmBtnText')}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
      >
        {t('notFoundPackage')}
      </SweetAlert>,
    );
  };

  useEffect(() => {
    startInternalTime();
    const { id: packageId } = params;

    const pkg = packages.find(({ id }) => id - packageId === 0);
    if (pkg) {
      setPackageSelect(pkg);
      return;
    }
    handleShowAlert(t('notification'));
    setTimeout(() => {
      history.push(ROUTES.PRICING);
    }, 2000);
  }, []);

  const paymentOptions = [
    {
      icon: <CreditCardIcon />,
      label: 'Thẻ ATM nội địa/ Internet Banking',
      value: PAYMENT_METHOD.ATM,
    },
    {
      icon: <CreditCardIcon />,
      label: 'Thẻ thanh toán nội địa (Visa, MasterCard, JCB)',
      value: PAYMENT_METHOD.VISA,
    },
    {
      icon: <Icon>paypal</Icon>,
      label: 'PayPal',
      value: PAYMENT_METHOD.PAYPAL,
    },
    {
      icon: <AccountBalanceIcon />,
      label: 'Chuyển khoản ngân hàng',
      value: PAYMENT_METHOD.TRANSFER,
    },
  ];

  const [listBank, setListBank] = useState([]);

  const fetchListBank = async (params) => {
    const res = await apis.payment.getListBank(params);
    const { error, data } = res;
    if (error === 0) {
      setListBank(data);
    }
  };

  useEffect(() => {
    fetchListBank({});
  }, []);

  const [selectedBank, setSelectedBank] = useState();

  const [listBankTransfer, setListBankTransfer] = useState();

  const fetchListBankTransfer = async (params) => {
    const res = await apis.payment.getListBankTransfer(params);
    const { error, data } = res;
    if (error === 0) {
      setListBankTransfer(data);
    }
  };

  useEffect(() => {
    fetchListBankTransfer({});
  }, []);

  const handleChangeBankTransferSelect = (event) => {
    setSelectedBankTransfer(event.target.value);
    setSelectBankMessage('');
  };

  const handleChangeMethodPayment = (value) => {
    setMethodPayment(value.target.value);
    setSelectedBankTransfer('');
    setMethodPaymentMessage('');
  };

  const handleSelectBank = (bankId) => {
    setSelectedBank(bankId);
    setSelectBankMessage('');
  };

  const startInternalTime = () => {
    let temp = duration;
    setInternalId(
      setInterval(() => {
        temp -= 1;
        setDuration(temp);
        if (temp < 0) {
          clearInterval(internalId);
          history.push(ROUTES.PRICING);
        }
      }, 1000),
    );
  };

  const onConfirmOrder = async() =>{
    try{
      setLoadingPayment(true);
      setIsPaid(false);
      let bankId = null;
      switch (methodPayment) {
        case PAYMENT_METHOD.ATM:
          bankId = selectedBank;
          break;
        case PAYMENT_METHOD.TRANSFER:
          bankId = selectedBankTransfer;
          break;
      }
      const params = {
        package_id: packageSelect.id,
        type: methodPayment,
        bank_id: bankId,
      };
      const data = await apis.payment.createPayment(params);
      if (data && data.error === 0) {
        switch (methodPayment) {
          case PAYMENT_METHOD.TRANSFER:
            setBankInfoTransfer(data.bankInfo);
            break;
          default:
            window.location.href = data.url;
        }

        setIsPaid(true);
      }
    } catch(_){}
  };

  const onConfirmUpdatePhone = async (value) => {
    hideAlert();
    try {
      const res = await apis.auth.updateAccount({ phone: value.trim() });
      const { error } = res;
      if (error === 0) {
        dispatch(actions.auth.getUserInfo());
        onConfirmOrder();
      }
    } catch (_) { }
  };

  const handleCreateOrder = async () => {
    try {
      if (!methodPayment) {
        setMethodPaymentMessage(t('methodPaymentEmpty'));
        return;
      }
      if (PAYMENT_METHOD.ATM === methodPayment && !selectedBank) {
        setSelectBankMessage(t('selectBankEmpty'));
        return;
      }
      if (PAYMENT_METHOD.TRANSFER === methodPayment && !selectedBankTransfer) {
        setSelectBankMessage(t('selectBankEmpty'));
        return;
      }
      if (!phone) {
        setAlert(
          <SweetAlert
            input
            showCancel
            style={{ display: 'block', marginTop: '-100px' }}
            title={t('updatePhone')}
            onConfirm={(value) => {
              onConfirmUpdatePhone(value);
            }}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.info}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            cancelBtnText={t('cancel')}
          >
            {t('textUpdatePhone')}
          </SweetAlert>,
        );
        return;
      }
      onConfirmOrder();
      
    } catch (error) {
      handleShowAlert(error.message);
    }
    setLoadingPayment(true);
  };

  const msToTime = (duration) => {
    var seconds = parseInt(duration % 60),
      minutes = parseInt((duration / 60) % 60),
      hours = parseInt((duration / (60 * 60)) % 24);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds;
  };

  return (
    <div
      className={classes.backgroundPayment}
      style={{ backgroundImage: 'url(' + shutterstock + ')' }}
    >
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.cardSignup}>
            <CardBody className={classes.cardBody}>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={6}
                  className={cx({
                    [classes.left]: true,
                    [classes.relative]: true,
                  })}
                >
                  <div className={classes.left}>
                    <img
                      src={require('assets/img/Logo.jpg')}
                      alt="logo"
                      className={classes.img}
                    />
                  </div>
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={classes.questionContent}
                  >
                    {t('questionContentCreator')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.grayColor}
                  >
                    {t('answerQuestion')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={cx({
                      [classes.labelBold]: true,
                      [classes.grayColor]: true,
                    })}
                  >
                    {t('autoComplete')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.grayColor}
                  >
                    {t('onlyMinutes')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.grayColor}
                  >
                    {t('registerJoin')}
                  </Typography>
                  <Typography className={classes.labelBottom}>
                    <Link to="trang-chu" onClick={(e) => e.preventDefault()}>
                      www.aiclip.ai
                    </Link>
                  </Typography>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={6}
                  className={cx({
                    [classes.left]: true,
                    [classes.relative]: true,
                  })}
                >
                  <div className={classes.left}>
                    <h5
                      className={cx({
                        [classes.subTitle]: true,
                        [classes.labelBold]: true,
                      })}
                    >
                      {t('detail')}
                    </h5>
                    <div className={classes.header}>
                      <h2
                        className={cx({
                          [classes.title]: true,
                          [classes.labelBold]: true,
                        })}
                      >
                        {t('order')}
                      </h2>
                      <h5
                        className={cx({
                          [classes.subTitle]: true,
                          [classes.labelBold]: true,
                          [classes.errorMessage]: true,
                        })}
                      >
                        {msToTime(duration)}
                      </h5>
                    </div>
                    <Divider className={classes.diviTitle} />
                  </div>
                  <form className={classes.form}>
                    <GridContainer className={classes.formItem}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={4}
                        className={classes.formItemLabel}
                      >
                        <FormLabel className={classes.labelLeftHorizontal}>
                          {t('order')}:
                        </FormLabel>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={8}
                        className={classes.formItemValue}
                      >
                        <Typography variant="h6" component="span">
                          {packageSelect.title}
                        </Typography>
                        <Typography variant="body2" component="span">
                          {t(mapPricingTypes[packageSelect.title])}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer className={classes.formItem}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={4}
                        className={classes.formItemLabel}
                      >
                        <FormLabel className={classes.labelLeftHorizontal}>
                          {t('denominations')}:
                        </FormLabel>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={8}
                        className={classes.formItemValue}
                      >
                        <Typography variant="h6" component="span">
                          {formatNumber(packageSelect.sale)}
                        </Typography>
                        <Typography variant="body2" component="span">
                          {t('unitMonth')}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    {/* <GridContainer className={classes.formItem}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={4}
                        className={classes.formItemLabel}
                      >
                        <FormLabel className={classes.labelLeftHorizontal}>
                          {t('amount')}:
                        </FormLabel>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={8}
                        className={classes.formItemValue}
                      >
                        <Typography variant="h6" component="span">
                          {formatNumber(packageSelect.sale)}
                        </Typography>
                        <Typography variant="body2" component="span">
                          {t('unitMoney')}
                        </Typography>
                      </GridItem>
                    </GridContainer> */}
                    <GridContainer className={classes.formItem}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={4}
                        className={classes.formItemLabel}
                      >
                        <FormLabel className={classes.labelLeftHorizontal}>
                          {t('timeToBuy')}:
                        </FormLabel>
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={8}
                        className={classes.formItemValue}
                      >
                        <Typography variant="h6" component="span">
                          {moment(buyTime).format('DD/MM/YYYY HH:mm:ss')}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        className={classes.left}
                      >
                        <FormLabel className={classes.labelLeftHorizontal}>
                          {t('transactionForm')}:
                        </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <Select
                            fullWidth
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={methodPayment}
                            onChange={handleChangeMethodPayment}
                            inputProps={{
                              name: 'methodPayment',
                              id: 'simple-select-step-2',
                            }}
                            variant="outlined"
                          >
                            {paymentOptions.map((method, index) => {
                              return (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={method.value}
                                  key={index}
                                >
                                  <ListItemIcon>{method.icon}</ListItemIcon>
                                  <ListItemText primary={method.label} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <small className={classes.errorMessage}>
                            <i>{methodPayemtMessage}</i>
                          </small>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      {methodPayment === PAYMENT_METHOD.ATM && (
                        <PaymentATM
                          t={t}
                          messageError={selectBankMessage}
                          listBank={listBank}
                          selectedBank={selectedBank}
                          onChangeBankSelect={handleSelectBank}
                        />
                      )}
                      {methodPayment === PAYMENT_METHOD.VISA && (
                        <PaymentVISA t={t} />
                      )}
                      {methodPayment === PAYMENT_METHOD.PAYPAL && (
                        <PaymentPaypal t={t} />
                      )}
                      {methodPayment === PAYMENT_METHOD.TRANSFER && (
                        <PaymentTransfer
                          bankInfo={bankInfoTransfer}
                          onChangeBankSelect={handleChangeBankTransferSelect}
                          listBank={listBankTransfer}
                          messageError={selectBankMessage}
                          t={t}
                        />
                      )}
                    </GridContainer>
                    <div className={classes.right}>
                      {!isPaid && (
                        <Button
                          color="primary"
                          className={classes.right}
                          onClick={handleCreateOrder}
                          disabled={loadingPayment}
                        >
                          {loadingPayment && (
                            <CircularProgress
                              size={14}
                              className={classes.loading}
                            />
                          )}
                          {t('payment')}
                        </Button>
                      )}
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
            <Divider className={classes.diviBottom} />
          </Card>
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
};

export default Payment;
