import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';
import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  ...modalStyle(theme),
  ...buttonStyle,
  siderbar: {
    padding: '0 5px',
    maxHeight: 'calc(100vh - 120px)',
    height: 'calc(100vh - 120px)',
    position: 'relative',
    paddingTop: '15px',
    overflowY: 'scroll',
  },
  sidebarTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderBottom: '1px solid #C9C9C9',
    padding: '10px 0',
  },
  titleLabel: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    fontsize: '14px',
    marginRight: '0',
  },
  videoGroup: {
    padding: '10px 0',
    '& video': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  btnAddScene: {
    padding: '15px 20px',
    borderRadius: '10px',
    display: 'block',
    width: '100%',
    height: '120px',
    marginTop: '10px',
    textTransform: 'uppercase',
    backgroundColor: '#4A6AAE',
    color: '#fff',

    '& label': {
      color: '#fff',
      marginTop: '15px',
    },
  },
  linkMyVideo: {
    color: '#3157A7',
    fontSize: '16px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '16px',
      fontWeight: '500',
    },
  },
  boxPolicy: {
    // position: 'absolute',
    bottom: 0,
    left: 0,
    '& a': {
      color: '#3157A7',
    },
  },
  textError: {
    color: 'red',
    fontSize: '14px',
  },
  checkboxLabelControl: {
    margin: '20px 0px 14px',
    alignItems: 'center',
  },
  checkboxLabel: {
    fontSize: '0.875rem',
    marginLeft: '6px',
  },
  checkRoot: {
    padding: '6px',
    marginLeft: '-6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  checked: {
    color: '#266FB8 !important',
  },
  checkedIcon: {
    width: '14px',
    height: '14px',
    border: '1px solid #C6C6C6',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '6px',
    border: '1px solid #C6C6C6',
    borderRadius: '3px',
  },
  boxCreateLoading: {
    position: 'relative',
  },
  imgLoading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#4e4c4c',
    opacity: '0.6',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    '& img': {
      width: '18%',
      zIndex: 9,
    },
  },
}));
