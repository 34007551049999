import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Assignment } from '@material-ui/icons';

import NavPills from 'components/NavPills/NavPills';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import NoPermission from 'components/NoPermission';

import { ORDER_STATUS, ROUTES } from '../../constants';
import OrderForm from './OrderForm';
import useStyles from './index.style';

const OrderView = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { t } = useTranslation('order');
  const classes = useStyles();
  const history = useHistory();
  const [active, setActive] = useState(0);
  const [alert, setALert] = useState(null);

  const { isAdmin } = userInfo;

  const onChange = (index) => {
    setActive(index);
  };

  const handelGetAlert = useCallback((item) => {
    setALert(item);
  });

  if (isAdmin !== 1) {
    return (
      <NoPermission
        backText={t('backToHome')}
        handleBack={() => {
          history.push(ROUTES.HOME);
        }}
      />
    );
  }

  return (
    <GridContainer className={classes.backgroundTab}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h3 className={classes.cardIconTitle}>{t('paymentManager')}</h3>
          </CardHeader>
          <CardBody>
            <NavPills
              color=""
              active={active}
              onTabChange={onChange}
              tabs={[
                {
                  tabButton: t('orderWaiting'),
                  tabContent: (
                    <OrderForm
                      status={ORDER_STATUS.WAITING}
                      isActive={active == 0}
                      getAlert={handelGetAlert}
                    ></OrderForm>
                  ),
                },
                {
                  tabButton: t('orderSuccess'),
                  tabContent: (
                    <OrderForm
                      status={ORDER_STATUS.SUCCESS}
                      isActive={active == 1}
                    ></OrderForm>
                  ),
                },
                {
                  tabButton: t('orderFail'),
                  tabContent: (
                    <OrderForm
                      status={ORDER_STATUS.ERROR}
                      isActive={active == 2}
                    ></OrderForm>
                  ),
                },
              ]}
            ></NavPills>
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  );
};

export default OrderView;
