import {
  roseColor,
  grayColor,
  blackColor,
  whiteColor,
  cardTitle,
} from 'assets/jss/material-dashboard-pro-react.js';
import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.js';

const sidebarStyle = () => ({
  ...hoverCardStyle,
  container: {
    padding: '0 5px',
    height: '100%',
    overflow: 'auto',
  },
  sidebarTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  titleLabel: {
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    fontsize: '14px',
    marginRight: '0',
  },
  iconCheckbox: {
    display: 'none',
  },
  iconCheckboxChecked: {
    color: roseColor[0],
    '& > span:first-child': {
      borderColor: roseColor[0],
    },
  },
  controlRoot: {
    margin: 0,
  },
  controlLabel: {
    border: '1px solid',
    borderRadius: '20px',
    padding: '5px 10px',
    textTransform: 'uppercase',
    color: grayColor[0],
    fontSize: '12px',
  },
  btnBase: {
    padding: '15px 20px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '10px',
    textTransform: 'uppercase',

    '& img': {
      marginBottom: '12px',
    },
  },
  btnAddScene: {
    backgroundColor: 'lightgray',
    '& span': {
      fontsize: '18px',
    },
  },
  btnCreateVideo: {
    backgroundColor: '#4A6AAE',
    color: whiteColor,

    '& span': {
      fontWeight: 'bold',
      fontsize: '24px',
    },
  },
  boxCreateVideo: {
    position: 'relative',
  },
  imgLoading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#4e4c4c',
    opacity: '0.6',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9,
    '& img': {
      width: '15%',
      height: '25%',
    },
  },
  img: {
    width: '100%',
  },
  sceneItem: {
    marginTop: '10px',
  },
  descriptionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0px',
  },
  mainScene: {
    // padding: '5px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100% - 50px)',
  },
  sceneList: {
    overflow: 'auto',
  },
  cardTitle: {
    ...cardTitle,
    marginTop: '0px',
    marginBottom: '3px',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: '0px',
    marginBottom: '3px',
    textAlign: 'center',
  },
  card: {
    background: 'none',
    boxShadow: 'none',
    color: grayColor[0],
  },
  cardBody: {
    padding: 0,
  },
  cardHeaderImage: {
    margin: '0px !important',
  },
  cardSelected: {
    color: blackColor,
  },
  cardHeaderSelected: {
    boxShadow: `0 1px 4px 5px rgb(0 0 0 / 14%)`,
    padding: '1px',
  },
  iconCheck: {
    maxWidth: '8px',
  },
  sceneContainer: {
    position: 'relative',
    padding: '12px 0px',
    cursor: 'pointer',
  },
  sceneContent: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  sceneOrder: {
    margin: '0px 8px 0px 0px',
    color: '#3157A7',
    fontSize: '18px',
    fontWeight: 'bold',
    width: '33px',
  },
  sceneImage: {
    // width: 'calc(100% - 33px)',
    opacity: '0.7',
    '& img': {
      maxWidth: '100%',
      borderRadius: '5px',
    },
  },
  sceneImageActive: {
    opacity: '1.0',
  },
  delButton: {
    padding: '0px',
    minWidth: 'unset',
    position: 'absolute',
    top: '0px',
    right: '-12px',
    borderRadius: '50%',
  },
  checkboxLabelControl: {
    margin: '20px 0px 14px',
    alignItems: 'center',
  },
  checkboxLabel: {
    fontSize: '0.875rem',
    marginLeft: '6px',
  },
  checkRoot: {
    padding: '6px',
    marginLeft: '-6px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  checked: {
    color: '#266FB8 !important',
  },
  checkedIcon: {
    width: '14px',
    height: '14px',
    border: '1px solid #C6C6C6',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '6px',
    border: '1px solid #C6C6C6',
    borderRadius: '3px',
  },
  termsAndCondition: {
    '& a': {
      color: '#3157A7',
    },
  },
});

export default sidebarStyle;
