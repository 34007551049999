import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './index.style';
import Table from 'components/Table/Table';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import CustomButton from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import moment from 'moment';
import Datetime from 'react-datetime';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useSelector } from 'react-redux';
import Assignment from '@material-ui/icons/Assignment';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import apis from 'apis';
import CardFooter from 'components/Card/CardFooter';
import TablePaginationActions from 'components/Table/TablePaginationActions';
import { omitIsNil } from 'utils/omit';
import { useSnackbar } from 'notistack';

const ManagerAccount = () => {
  const classes = useStyles();
  const { t } = useTranslation(['managerAccount', 'translation']);

  const { packages } = useSelector((state) => state.payment);

  const [listAccount, setListAccount] = useState([]);

  const [showDialogEdit, setShowDialogEdit] = useState(false);
  const [showDialogCreateOrder, setShowDialogCreateOrder] = useState(false);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState();
  const [videoNumber, setVideoNumber] = useState('');
  const [idAccount, setIdAccount] = useState('');
  const [valuePackage, setValuePackage] = useState('');
  const [valueSearch, setValueSearch] = useState({
    id: '',
    phone: '',
    email: '',
  });
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = (item) => {
    setShowDialogEdit(true);
    setEmail(item.email);
    setExpiryDate(item.expiryDate);
    setPhoneNumber(item.phone);
    setVideoNumber(item.totalVideoAvailable);
    setIdAccount(item.id);
  };

  const handleChangePackage = (item) => {
    setVideoNumber(item.numberVideo);
    const date = new Date();
    setExpiryDate(date.setDate(date.getDate() + item.expireKind));
  };

  const handleCreateOrder = (item) => {
    setShowDialogCreateOrder(true);
    setIdAccount(item.id);
    setExpiryDate('');
    setVideoNumber(0);
  };

  const handleGetListAccount = async (params) => {
    try {
      const data = await apis.account.getListAccount({
        page,
        limit: rowsPerPage,
        ...params,
      });
      if (data.error === 0) {
        setTotal(data.total);
        setListAccount(data.list);
      }
    } catch (err) { }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleGetListAccount({ page: newPage, limit: rowsPerPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    handleGetListAccount({
      page: 0,
      limit: parseInt(event.target.value, 10),
    });
  };

  useEffect(() => {
    handleGetListAccount();
  }, []);

  const onSearch = () => {
    let params = {};
    if (valueSearch.id) {
      params = { ...params, id: valueSearch.id.trim() };
    }
    if (valueSearch.phone) {
      params = { ...params, phone: valueSearch.phone.trim() };
    }
    if (valueSearch.email) {
      params = { ...params, email: valueSearch.email.trim() };
    }
    handleGetListAccount(params);
  };

  const onChangeValueSearch = (item, value) => {
    if (item === 'id') {
      if (!value && !valueSearch.phone && !valueSearch.email)
        handleGetListAccount();
      setValueSearch({ ...valueSearch, id: value });
    }
    if (item === 'email') {
      if (!value && !valueSearch.phone && !valueSearch.id)
        handleGetListAccount();
      setValueSearch({ ...valueSearch, email: value });
    }
    if (item === 'phone') {
      if (!value && !valueSearch.id && !valueSearch.email)
        handleGetListAccount();
      setValueSearch({ ...valueSearch, phone: value });
    }
  };

  const onConfirmUpdateAccount = async () => {
    const accountInfo = {
      email: email ? email.trim() : '',
      phone: phoneNumber ? phoneNumber.trim() : '',
    };
    omitIsNil(accountInfo, { deep: false });
    try {
      const data = await apis.account.updateAccount(accountInfo);
      if (data.error === 0) {
        setShowDialogEdit(false);
        enqueueSnackbar(t('updateAccountSuccess'), {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      } else {
        enqueueSnackbar(t('updateAccountError'), {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
    } catch (err) { }
  };

  const onConfirmCreateOrder = async () => {
    const orderInfo = {
      acccount_id: idAccount,
      package_id: valuePackage,
    };
    omitIsNil(orderInfo, { deep: false });
    try {
      const data = await apis.payment.createPaymentWithCms(orderInfo);
      if (data.error === 0) {
        enqueueSnackbar(t('createOrderSuccess'), {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        setShowDialogCreateOrder(false);
      } else {
        enqueueSnackbar(t('createOrderError'), {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      }
    } catch (err) { }
  };

  const tableData = () => {
    if (listAccount) {
      return listAccount.map((item, index) => {
        return [
          index + 1,
          item.id,
          item.username,
          item.phone,
          item.email,
          item.package ? item.package.title : '',
          item.package ? item.expireKind : '',
          item.totalVideoAvailable,
          item.totalVideoCurrentUse,
          item.totalBluevideo,
          item.totalTemplate,
          <div className={classes.actions}>
            <Tooltip title={t('edit')}>
              <Button aria-label="delete" onClick={() => handleEdit(item)}>
                <EditIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t('createOrder')}>
              <Button
                aria-label="delete"
                onClick={() => handleCreateOrder(item)}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Tooltip>
          </div>,
        ];
      });
    } else return [];
  };

  useEffect(() => {
    tableData();
  }, [listAccount]);

  return (
    <div className={classes.background}>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h3 className={classes.cardIconTitle}>Quản lý tài khoản</h3>
        </CardHeader>
        <CardBody>
          <div className={classes.boxSearch}>
            <Input
              className={classes.inputSearch}
              value={valueSearch.id}
              onChange={(e) => {
                onChangeValueSearch('id', e.target.value); // Set undefined to remove the filter entirely
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onSearch();
              }}
              placeholder={t('enterId')}
            />
            <Input
              className={classes.inputSearch}
              value={valueSearch.phone}
              onChange={(e) => {
                onChangeValueSearch('phone', e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onSearch();
              }}
              placeholder={t('enterPhone')}
            />
            <Input
              className={classes.inputSearch}
              value={valueSearch.email}
              onChange={(e) => {
                onChangeValueSearch('email', e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onSearch();
              }}
              placeholder={t('enterEmail')}
            />
            <Button className={classes.btnSearch} onClick={() => onSearch()}>
              {t('search')}
            </Button>
          </div>
          <Table
            tableHead={[
              {
                minWidth: '50',
                title: 'STT',
              },
              {
                minWidth: '80',
                title: 'ID',
              },
              {
                minWidth: '200',
                title: t('name'),
              },
              {
                minWidth: '150',
                title: t('phoneNumber'),
              },
              {
                minWidth: '150',
                title: 'Email',
              },
              {
                minWidth: '200',
                title: t('packageUse'),
              },
              {
                minWidth: '150',
                title: t('expiry'),
              },
              {
                minWidth: '150',
                title: t('availableVideo'),
              },
              {
                minWidth: '150',
                title: t('usedVideo'),
              },
              {
                minWidth: '200',
                title: t('greenVideo'),
              },
              {
                minWidth: '150',
                title: t('templateVideo'),
              },
              {
                minWidth: '150',
                title: t('action'),
              },
            ]}
            tableData={tableData()}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
            customHeadCellClasses={[
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
              `${classes.center} ${classes.bold}`,
            ]}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
          />
        </CardBody>
        <CardFooter>
          <TablePagination
            className={classes.tablePagination}
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </CardFooter>
      </Card>

      <Dialog
        className={classes.dialogPreview}
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal,
        }}
        open={Boolean(showDialogEdit)}
        keepMounted
        onClose={false}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h3
            className={classNames({
              [classes.modalTitle]: true,
              [classes.left]: true,
            })}
          >
            {t('editAccount')}
          </h3>
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowDialogEdit(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          <form>
            <CustomInput
              labelText="ID"
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                className: classes.input,
                disabled: classes.disabled,
                value: idAccount,
                onChange: (e) => {
                  setIdAccount(e.target.value); // Set undefined to remove the filter entirely
                },
                placeholder: 'ID',
              }}
            />
            <CustomInput
              labelText="Email"
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                className: classes.input,
                value: email,
                onChange: (e) => {
                  setEmail(e.target.value); // Set undefined to remove the filter entirely
                },
                placeholder: 'Email',
              }}
            />
            <CustomInput
              labelText={t('phoneNumber')}
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                className: classes.input,
                value: phoneNumber,
                onChange: (e) => {
                  setPhoneNumber(e.target.value); // Set undefined to remove the filter entirely
                },
                placeholder: t('phoneNumber'),
              }}
            />
            <CustomInput
              labelText={t('availableVideo')}
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                className: classes.input,
                value: videoNumber,
                onChange: (e) => {
                  setVideoNumber(e.target.value); // Set undefined to remove the filter entirely
                },
                placeholder: t('availableVideo'),
              }}
            />
            <InputLabel className={classes.labelExpiryDate}>
              {t('expiry')}
            </InputLabel>
            <Datetime
              inputProps={{ placeholder: t('expiry') }}
              dateFormat={'DD-MM-YYYY'}
              timeFormat={'HH:MM'}
              value={moment(expiryDate).format('DD-MM-YYYY HH:MM')}
              onChange={(e) => {
                setExpiryDate(e.target.value);
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.btnCancel}
            onClick={() => setShowDialogEdit(false)}
          >
            {t('translation:::cancel')}
          </Button>
          <Button
            className={classes.btnAccept}
            onClick={() => onConfirmUpdateAccount()}
          >
            {t('translation:::accept')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        className={classes.dialogCreateOrder}
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal,
        }}
        open={Boolean(showDialogCreateOrder)}
        keepMounted
        onClose={false}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h3
            className={classNames({
              [classes.modalTitle]: true,
              [classes.left]: true,
            })}
          >
            {t('createOrder')}
          </h3>
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowDialogCreateOrder(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          <form>
            <CustomInput
              labelText="ID"
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                className: classes.input,
                disabled: classes.disabled,
                value: idAccount,
                placeholder: 'ID',
              }}
            />
            <div className={classes.selectFullWidth}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.labelExpiryDate}
              >
                {t('selectPackage')}
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={valuePackage}
                onChange={(e) => {
                  setValuePackage(e.target.value);
                }}
                inputProps={{
                  name: 'simpleSelect',
                  id: 'simple-select',
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {t('selectPackage')}
                </MenuItem>
                {packages.map((item, index) => {
                  return (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.id}
                      onClick={() => handleChangePackage(item)}
                    >
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <CustomInput
              labelText={t('availableVideo')}
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                className: classes.input,
                value: videoNumber,
                placeholder: t('availableVideo'),
              }}
            />
            <InputLabel className={classes.labelExpiryDate}>
              {t('expiry')}
            </InputLabel>
            <Datetime
              inputProps={{ placeholder: t('expiry') }}
              dateFormat={'DD-MM-YYYY'}
              timeFormat={'HH:MM'}
              value={moment(expiryDate).format('DD-MM-YYYY HH:MM')}
              onChange={(e) => {
                setExpiryDate(e.target.value);
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.btnCancel}
            onClick={() => setShowDialogCreateOrder(false)}
          >
            {t('translation:::cancel')}
          </Button>
          <Button
            className={classes.btnAccept}
            onClick={() => onConfirmCreateOrder()}
          >
            {t('translation:::accept')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManagerAccount;
