import { makeStyles } from '@material-ui/core/styles';
import { container } from 'assets/jss/material-dashboard-pro-react';

export default makeStyles(() => ({
  background: {
    backgroundColor: '#FFFFFF',
    '& p,& li': {
      fontSize: '16px',
      lineHeight: '30px',
      wordSpacing: '1px',
    },
    '& li': {
      paddingBottom: '10px',
    },
  },
  container: {
    ...container,
    paddingBottom: '50px',
  },
  heading: {
    fontWeight: '500',
    fontSize: '20px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '30px',
    textAlign: 'center',
    letterSpacing: '1px !important',
    textTransform: 'uppercase',
  },
}));
