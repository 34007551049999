import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    zIndex: 2,
    marginTop: '110px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '75px',
    },
  },
}));
