import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { KeyboardArrowLeft, Check } from '@material-ui/icons';

import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CustomOutlinedInput from 'components/CustomInput/CustomOutlinedInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoHeader from 'components/InfoHeader/InfoHeader';
import SocialLogin from 'components/SocialLogin';

import useStyles from './index.style';
import { IMAGES, ROUTES } from '../../constants';

const Login = () => {
  const classes = useStyles();

  const { t } = useTranslation('login');
  const history = useHistory();

  const onRegister = () => {
    history.push(ROUTES.REGISTER);
  };

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem>
            <Card className={classes.card}>
              <CardBody className={classes.cardBody}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <img
                      className={classes.logo}
                      src={IMAGES.logo}
                      alt="logo"
                    />
                    <h2 className={classes.title}>{t('createVideo')}</h2>
                    <h4 className={classes.text}>{t('scriptToVideo')}</h4>
                    <h4 className={`${classes.text} ${classes.textAuto}`}>
                      {t('automatic')}
                    </h4>
                    <h4 className={classes.text}>{t('justFewMinutes')}</h4>
                    <h4 className={`${classes.text} ${classes.textRegister}`}>
                      {t('registerNow')}
                    </h4>
                    <div className={classes.linkWrapper}>
                      <a className={classes.link} href={''}>
                        www.aiclip.ai
                      </a>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={6} className={classes.right}>
                    <InfoHeader info={t('welcome')} title={t('login')} />

                    {/* <form>
                      <CustomOutlinedInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          className: classes.input,
                          placeholder: t('email'),
                        }}
                      />
                      <CustomOutlinedInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          className: classes.input,
                          placeholder: t('password'),
                        }}
                      />
                    </form>
                    <div className={classes.rowSpaceBetween}>
                      <FormControlLabel
                        classes={{
                          root: classes.checkboxLabelControl,
                          label: classes.checkboxLabel,
                        }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        label={<span>{t('remmemberAccount')}</span>}
                      />
                      <Button className={classes.btnLogin}>{t('login')}</Button>
                    </div> */}
                    <SocialLogin />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <div className={classes.rowButtons}>
                {/* <Button
                  className={classes.button}
                  color="white"
                  simple
                  onClick={onRegister}
                >
                  <KeyboardArrowLeft className={classes.icons} />{' '}
                  {t('register')}
                </Button> */}
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default Login;
