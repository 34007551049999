import React from 'react';

import useStyles from './index.style';

const Content = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export default Content;
