import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Button } from '@material-ui/core';
import classNames from 'classnames';

import { IMAGES } from '../../constants';
import useStyles from './index.style';

const SampleNextArrow = (props) => {
  const { onClick } = props;
  const classes = useStyles();

  return (
    <Button
      className={classNames(classes.sliderButton, classes.sliderNext)}
      onClick={onClick}
    >
      <img src={IMAGES.sliderNextIcon} />
    </Button>
  );
};
SampleNextArrow.propTypes = {
  onClick: PropTypes.func,
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  const classes = useStyles();

  return (
    <Button
      className={classNames(classes.sliderButton, classes.sliderPrev)}
      onClick={onClick}
    >
      <img src={IMAGES.sliderPrevIcon} alt="slider-prev-icon" />
    </Button>
  );
};
SamplePrevArrow.propTypes = {
  onClick: PropTypes.func,
};

const CustomSlider = (props) => {
  const { children, ...settings } = props;
  const classes = useStyles();

  return (
    <div className={classes.sliderContainer}>
      <Slider
        {...settings}
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
      >
        {children}
      </Slider>
    </div>
  );
};

export default CustomSlider;

CustomSlider.propTypes = {
  children: PropTypes.node,
};
