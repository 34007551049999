import {
  dangerColor,
  grayColor,
} from 'assets/jss/material-dashboard-pro-react.js';

const paymentAtm = () => ({
  imageBanking: {
    border: '1px solid white',
    borderRadius: '5px',
    transition: '0.5s',
  },
  activeBorderImage: {
    border: '1px solid aquamarine',
    borderRadius: '15px',
    transform: 'scale(1.4)',
  },
  errorMessage: {
    color: dangerColor[0],
  },
  labelLeftHorizontal: {
    color: grayColor[0],
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    marginRight: '0',
  },
});

export default paymentAtm;
