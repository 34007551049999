import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './index.style';

const Policy = () => {
  const classes = useStyles();
  const { t } = useTranslation('policy');

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <h2 className={classes.title}>{t('policy')}</h2>
        <h3 className={classes.heading}>{t('title1')}</h3>
        <p>{t('content11')}</p>
        <ul>
          <li>{t('content12')}</li>
          <li>{t('content13')}</li>
          <li>{t('content14')}</li>
        </ul>

        <h3 className={classes.heading}>{t('title2')}</h3>
        <p>{t('content21')}</p>
        <ul>
          <li>{t('content22')}</li>
          <li>{t('content23')}</li>
          <li>{t('content24')}</li>
          <li>{t('content25')}</li>
          <li>{t('content26')}</li>
          <li>{t('content27')}</li>
          <li>{t('content28')}</li>
        </ul>

        <h3 className={classes.heading}>{t('title3')}</h3>
        <p>{t('content30')}</p>

        <h3 className={classes.heading}>{t('title4')}</h3>
        <p>{t('content41')}</p>
        <p>{t('content42')}</p>
        <p>{t('content43')}</p>
        <p>{t('content44')}</p>

        <h3 className={classes.heading}>{t('title5')}</h3>
        <p>{t('content51')}</p>
        <p>{t('content52')}</p>

        <h3 className={classes.heading}>{t('title6')}</h3>
        <p>{t('content60')}</p>
        <ul>
          <li>{t('content61')}</li>
          <li>{t('content62')}</li>
          <li>{t('content63')}</li>
        </ul>
      </div>
    </div>
  );
};

export default Policy;
