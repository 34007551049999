import { Button } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { IMAGES } from '../../constants';
import { API_DOMAIN, FACEBOOK_APP_ID } from '../../configs';
import actions from '../../redux/actions';
import useStyles from './index.style';

const SocialLogin = () => {
  const classes = useStyles();
  const { t } = useTranslation('login');

  const dispatch = useDispatch();

  const responseFacebook = (data) => {
    const { accessToken } = data;
    if (accessToken) {
      dispatch(actions.auth.loginWithFacebook(accessToken));
    }
  };

  const handleLoginGoogle = () => {
    window.location.assign(`${API_DOMAIN}/api/public/auth/google`);
  };

  return (
    <div>
      <h4 className={classes.socialTitle}>{t('socialLogin')}</h4>
      <div className={classes.socialButtons}>
        <div className={classes.marginRight}>
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            fields="name,email"
            callback={responseFacebook}
            render={(renderProps) => (
              <Button onClick={renderProps.onClick}>
                <img src={IMAGES.facebookIcon} alt="facebook-icon" />
              </Button>
            )}
          />
        </div>
        <Button onClick={handleLoginGoogle}>
          <img src={IMAGES.googleIcon} alt="google-icon" />
        </Button>
      </div>
    </div>
  );
};

export default SocialLogin;
