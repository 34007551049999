import { makeStyles } from '@material-ui/core';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

export default makeStyles((theme) => ({
  ...modalStyle(theme),
  ...buttonStyle,
  mainContent: {
    padding: '0 5px',
    color: '#3157A7',
    maxHeight: 'calc(100vh - 120px)',
    overflow: 'auto',
    '& .header': {
      marginBottom: '40px',
    },
  },
  title: {
    textAlign: 'center',
    color: '#3157A7',
  },
  titleH3: {
    fontSize: '22px',
    fontWeight: 'normal',
  },
  titleH4: {
    fontSize: '18px',
    fontWeight: 'normal',
  },
  titleH2: {
    fontSize: '23px',
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  itemProcedure: {
    textAlign: 'center',
    position: 'relative',
    height: '100%',
    marginBottom: '20px',
    '& img': {
      height: '210px',
      maxWidth: '100%',
    },
    '& p': {
      fontSize: '14px',
      marginTop: '20px',
      fontWeight: 'normal',
    },
  },
  iconNextScreen: {
    height: 'auto !important',
    position: 'absolute',
    width: 'auto',
    top: '40%',
    left: 0,
  },
  editor: {
    width: '100%',
    height: '100%',
    cursor: 'text',
    fontWeight: '400',
    lineHeight: '1.5',
    verticalAlign: 'baseline',
    padding: '10px 15px',
    fontSize: '16px',
    border: 'none',
    background: 'transparent',
    resize: 'none',
    fontFamily: 'Roboto, sans-serif',
    '&[placeholder]:empty:before': {
      content: 'attr(placeholder)',
      color: '#555',
    },
  },
  groupUploadFile: {
    position: 'relative',
    textAlign: 'center',
    padding: '8px',
    border: '1px solid #c4c4c4',
  },
  uploadFile: {
    '& svg': {
      width: '100%',
      height: '100px',
    },
    '& p': {
      fontSize: '24px',
      marginTop: '20px',
    },
  },
  btnUploadFile: {
    border: '1px solid',
    padding: '9px 20px 6px 20px',
    color: '#3157A7',
  },
  inputUploadFile: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    maxHeight: '100%',
    width: '100%',
    maxWidth: '100%',
    opacity: 0,
  },
  tabs: {
    width: '100%',
    marginTop: '-15px',
    '& a': {
      color: '#3157A7',
    },
    '& button': {
      color: '#3157A7',
      fontWeight: 'normal',
      fontSize: '15px',
      textTransform: 'none',
      '&:first-child': {
        paddingLeft: 0,
        minWidth: 0,
      },
      '&.Mui-selected': {
        textTransform: 'uppercase',
        borderBottom: '2px solid #3157A7',
        borderRadius: 0,
        fontWeight: '500',
      },
    },
    '& .react-swipeable-view-container': {
      '& div[aria-hidden="true"]': {
        height: '10px',
      },
      '& div[aria-hidden="false"]': {
        paddingRight: '1px',
      },
    },
  },
  dialogSpeed: {
    '& $modalHeader': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      '& h3': {
        fontSize: '20px',
        margin: 0,
      },
    },
    fontSize: '16px',
  },
  sliderSpeed: {
    display: 'flex',
  },
  textSpeed: {
    marginLeft: '15px',
    padding: '3px 10px',
    border: '1px solid #c4c4c4',
  },
  btnAccept: {
    border: '1px solid #3157A7',
    background: '#3157A7',
    color: '#fff',
    padding: '7px 20px 3px 20px',
    '&:hover': {
      color: '#3157A7',
    },
  },
  iconLoadPlay: {
    width: '18px',
  },
  ratePlayAudio: {
    '& $rateAudio': {
      margin: '0 7px',
    },
    '& $span00s': {
      margin: '0 7px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& audio': {
      display: 'none',
    },
  },
  policyRow: {
    display: 'flex',
    alignItems: 'center',
    color: '#383838',
    fontSize: '18px',
    fontWeight: 'normal',
    marginTop: '15px',
    '& p': {
      margin: 0,
    },
  },
  formControl: {
    flexDirection: 'row',
    width: '100%',
    border: '1px solid',
  },
  selectLanguage: {
    width: '200px',
    padding: '0 15px',
    '&:before': {
      borderBottom: 'none',
    },
  },
  selectVoice: {
    width: 'calc(100% - 200px)',
    height: '45px',
    padding: '0 15px',
    '&:before': {
      borderBottom: 'none',
    },
  },
  btnTryListen: {
    padding: '7px 20px 5px 20px',
  },
  table: {
    padding: '15px',
    border: '1px solid',
    position: 'relative',
  },
  inputTitle: {
    '&.MuiInputBase-root': {
      width: '100%',
      '&:before': {
        display: 'none',
      },
      '& input': {
        border: '1px solid #c4c4c4',
        height: '25px',
        lineHeight: '44px',
        padding: '6px 15px',
      },
    },
  },
  textError: {
    color: 'red',
    fontSize: '14px',
  },
  boxVoiceListen: {
    margin: '20px 0',
  },
  iconDelete: {
    color: '#FF8181 !important',
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    opacity: '0.5',
    '& img': {
      width: '30px',
    },
  },
  maxText: {
    textAlign: 'right',
    marginBottom: '5px',
    marginTop: '10px',
  },
}));
