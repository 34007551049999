import { grayColor } from 'assets/jss/material-dashboard-pro-react.js';

const paymentVisa = () => ({
  labelLeftHorizontal: {
    color: grayColor[0],
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    marginRight: '0',
  },
});

export default paymentVisa;
