import React, { useCallback, useState } from 'react';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import Asidebar from './Asidebar';

export default function CreateVideo() {
  const [linkAudioImage, setLinkAudioImage] = useState([]);
  const [listData, setListData] = useState([]);

  const onSetAudioImage = useCallback((item) => {
    setLinkAudioImage(item);
  }, []);

  const setListDataSentence = useCallback((item) => {
    setListData(item);
  }, []);

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={2}>
          <Sidebar listData={listData} linkAudioImage={linkAudioImage} />
        </GridItem>
        <GridItem xs={12} sm={12} md={7}>
          <MainContent setListDataSentence={setListDataSentence} />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Asidebar setAudioImage={onSetAudioImage} />
        </GridItem>
      </GridContainer>
    </div>
  );
}
