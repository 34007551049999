import React, { useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  ClickAwayListener,
  Divider,
  Grow,
  // Link,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

// core components
import Button from 'components/CustomButtons/Button';
import styles from 'assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js';

import { API_DOMAIN, PUBLIC_URL } from '../../configs';
import { enNamespace, viNamespace } from '../../languages/config';
import { IMAGES, ROUTES } from '../../constants';
import actions from 'redux/actions';

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const { t, i18n } = useTranslation();
  const { accessToken, userInfo } = useSelector((state) => state.auth);
  const { username, avatar, packageId, isAdmin } = userInfo || {};

  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleOpenVideo = () => {
    handleCloseProfile();
    history.push(ROUTES.VIDEOS);
  };

  const handleOpenVideoGreenScreen = () => {
    handleCloseProfile();
    history.push(ROUTES.GREEN_SCREEN);
  };

  const handleOpenVideoTemplate = () => {
    handleCloseProfile();
    history.push(ROUTES.CREATE_VIDEO);
  };

  const handleGotoHistory = () => {
    history.push(ROUTES.TRANSACTION_HISTORY);
  };

  const handleGotoPaymentManager = () => {
    history.push(ROUTES.ORDER_VIEW);
  };

  const handleGotoAccountManager = () => {
    history.push(ROUTES.MANAGER_ACCOUNT);
  };

  const handleLogout = () => {
    dispatch(actions.auth.logout());
  };

  const [openLanguage, setOpenLanguage] = React.useState(null);
  const handleClickLanguage = (event) => {
    if (openLanguage && openLanguage.contains(event.target)) {
      setOpenLanguage(null);
    } else {
      setOpenLanguage(event.currentTarget);
    }
  };
  const handleCloseLanguage = () => {
    setOpenLanguage(null);
  };

  const handleChangeLanguage = (namespace) => async () => {
    await i18n.changeLanguage(namespace);
    localStorage.setItem('i18nextLng', namespace);
    handleCloseLanguage();
  };

  useEffect(() => {
    const i18nextLng = localStorage.getItem('i18nextLng');
    const namespace = i18nextLng === enNamespace ? enNamespace : viNamespace;
    handleChangeLanguage(namespace)();
  }, []);

  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });

  const dropdownItem = cx(classes.dropdownItem);

  const managerClasses = cx({
    [classes.managerClasses]: true,
  });

  var list = (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <NavLink to={'/'} className={classes.navLink}>
          {t('introduce')}
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to={ROUTES.PRICING}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute(ROUTES.PRICING),
          })}
        >
          {t('pricing')}
        </NavLink>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <NavLink
          to={'/news-page'}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute('/auth/news-page'),
          })}
        >
          {t('news')}
        </NavLink>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <NavLink
          to={{
            pathname: `${PUBLIC_URL}/DIEU_KHOAN_SU_DUNG_AICLIP.pdf`,
          }}
          className={classes.navLink}
          target="_blank"
        >
          {t('customerCare')}
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to={{
            pathname:
              'https://www.youtube.com/playlist?list=PLcE0yt7NYtDpQlDO6F85yltfaNSI14_hB',
          }}
          target="_blank"
          className={classes.navLink}
        >
          {t('instruction')}
        </NavLink>
      </ListItem>
      {!accessToken && (
        <>
          <ListItem className={classes.listItem}>
            <NavLink
              to={ROUTES.LOGIN}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: activeRoute(ROUTES.LOGIN),
                [classes.borderLogin]: true,
              })}
            >
              {t('login')}
            </NavLink>
          </ListItem>
          {/* <ListItem className={classes.listItem}>
            <NavLink
              to={ROUTES.REGISTER}
              className={cx(classes.navLink, {
                [classes.navLinkActive]: activeRoute(ROUTES.REGISTER),
                [classes.borderRegister]: true,
              })}
            >
              {t('register')}
            </NavLink>
          </ListItem> */}
        </>
      )}
      <ListItem className={classes.listItem}>
        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="language"
            aria-owns={openLanguage ? 'language-menu-list' : null}
            aria-haspopup="true"
            onClick={handleClickLanguage}
            className={classes.buttonLink}
          >
            <ListItemText
              primary={t('language')}
              disableTypography={true}
              className={classes.listItemText}
              secondary={<b className={classes.caret} />}
            />
          </Button>
          <Popper
            open={Boolean(openLanguage)}
            anchorEl={openLanguage}
            transition
            disablePortal
            placement="bottom"
            className={cx({
              [classes.popperClose]: !openLanguage,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="notification-menu-list"
                style={{ transformOrigin: '0 0 0' }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleCloseLanguage}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={handleChangeLanguage(viNamespace)}
                        className={dropdownItem}
                      >
                        {t('vi')}
                      </MenuItem>
                      <MenuItem
                        onClick={handleChangeLanguage(enNamespace)}
                        className={dropdownItem}
                      >
                        {t('en')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {/* </Hidden> */}
        </div>
      </ListItem>
      {accessToken && (
        <ListItem className={cx(classes.listItem, classes.accountContainer)}>
          <div className={classes.userSettings}>
            <div>
              <div className={classes.usename}>{username}</div>
              <div className={classes.upVip}>
                <Link to={ROUTES.PRICING}>
                  {packageId ? t('buyMore') : t('upVip')}
                  <img src={IMAGES.star} alt="" />
                </Link>
              </div>
            </div>
            <div className={managerClasses}>
              <div>
                <Button
                  color="transparent"
                  aria-label="Person"
                  aria-owns={openProfile ? 'profile-menu-list' : null}
                  aria-haspopup="true"
                  onClick={matches ? handleClickProfile : null}
                  onMouseEnter={matches ? handleClickProfile : null}
                  className={classes.avatar}
                >
                  <img
                    src={avatar ? `${API_DOMAIN}${avatar}` : IMAGES.avartaUser}
                    alt=""
                  />
                </Button>
              </div>
              <Popper
                open={Boolean(openProfile)}
                anchorEl={openProfile}
                transition
                disablePortal
                placement="bottom"
                className={cx({
                  [classes.popperClose]: !openProfile,
                  [classes.popperResponsive]: true,
                  [classes.popperNav]: true,
                })}
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                    id="profile-menu-list"
                    style={{ transformOrigin: '0 0 0' }}
                  >
                    <Paper className={classes.dropdown}>
                      <ClickAwayListener onClickAway={handleCloseProfile}>
                        <MenuList role="menu">
                          <MenuItem
                            onClick={handleOpenVideo}
                            className={dropdownItem}
                          >
                            {t('myVideos')}
                          </MenuItem>
                          <MenuItem
                            onClick={handleOpenVideoTemplate}
                            className={dropdownItem}
                          >
                            {t('showVideoTemplate')}
                          </MenuItem>
                          <MenuItem
                            onClick={handleOpenVideoGreenScreen}
                            className={dropdownItem}
                          >
                            {t('showMCGreen')}
                          </MenuItem>
                          <MenuItem
                            onClick={handleGotoHistory}
                            className={dropdownItem}
                          >
                            {t('showHistoryOrder')}
                          </MenuItem>
                          {isAdmin === 1 && (
                            <MenuItem
                              onClick={handleGotoPaymentManager}
                              className={dropdownItem}
                            >
                              {t('paymentManager')}
                            </MenuItem>
                          )}
                          {/* {isAdmin === 1 && (
                            <>
                              <MenuItem
                                onClick={handleGotoAccountManager}
                                className={dropdownItem}
                              >
                                {t('showManagerAccount')}
                              </MenuItem>
                            </>
                          )} */}
                          <Divider light />
                          <MenuItem
                            onClick={handleLogout}
                            className={dropdownItem}
                          >
                            {t('logout')}
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </ListItem>
      )}
    </List>
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            <Link to="/">
              <img className={classes.logo} src={IMAGES.logo} alt="img-logo" />
            </Link>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            <Link to="/" className={classes.title}>
              <img src={IMAGES.logo} alt="img-logo" />
            </Link>
          </div>
        </Hidden>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <img src={IMAGES.menuIcon} alt="img-icon" />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={'right'}
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  brandText: PropTypes.string,
};
