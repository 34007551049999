export const actionTypes = {
  GET_LIST_PACKAGES: 'GET_LIST_PACKAGES',
  GET_LIST_PACKAGES_SUCCESS: 'GET_LIST_PACKAGES_SUCCESS',
  GET_LIST_PACKAGES_FAILURE: 'GET_LIST_PACKAGES_FAILURE',
};

export function getListPackages() {
  return {
    type: actionTypes.GET_LIST_PACKAGES,
  };
}

export function getListPackagesSuccess(data) {
  return {
    type: actionTypes.GET_LIST_PACKAGES_SUCCESS,
    payload: data,
  };
}

export function getListPackagesFailure(message) {
  return {
    type: actionTypes.GET_LIST_PACKAGES_FAILURE,
    payload: message,
  };
}
