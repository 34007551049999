import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { IMAGES } from '../../constants';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react.js';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';

export default makeStyles((theme) => ({
  ...modalStyle(theme),
  homePage: {
    padding: '0',
    boxSizing: 'border-box',
    overflow: 'hidden',
    background: '#fff',
    scrollBehavior: 'smooth',
  },
  backgroundBanner: {
    height: '100%',
    '@media (min-width: 1920px)': {
      height: 'auto',
      overflow: 'hidden',
    },
    '@media (max-width: 1400px)': {
      height: 'max-content',
    },
  },
  bannerHome: {
    width: '100%',
    mixBlendMode: 'darken',
  },
  bannerLink: {
    fontSize: '18px',
    lineHeight: '26px',
    textAlign: 'center',
    letterSpacing: '-0.3px',
    color: '#ffffff',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '25px',
  },
  bigTitleBanner: {
    fontWeight: 'bold',
    fontSize: '50px',
    lineHeight: '59px',
    color: '#FFF',
    '@media (max-width: 1200px)': {
      color: '#4B4B4B',
    },
  },
  norTitleBanner: {
    fontWeight: 'bold',
    fontSize: '35px',
    lineHeight: '59px',
    color: '#FFF',
    '@media (max-width: 1200px)': {
      color: '#4B4B4B',
    },
  },
  textBanner: {
    margin: '12% 10%',
    position: 'absolute',
    top: '0',
    maxWidth: '605px',
    '@media (max-width: 1200px)': {
      position: 'relative',
      margin: '10% 8%',
      maxWidth: 'unset',
    },
  },
  paragraphBanner: {
    fontSize: '18px',
    lineHeight: '26px',
    textAlign: 'justify',
    color: '#FFFFFF',
    maxWidth: '700px',
    '@media (max-width: 1200px)': {
      color: '#4B4B4B',
    },
  },
  wrapIframeVideo: {
    position: 'relative',
  },
  iframeVideo: {
    position: 'absolute',
    top: '0px',
    left: '20%',
    width: '48.5%',
    height: '78%',
  },
  imgBgYoutube: {
    width: '100%',
    maxWidth: '100%',
  },
  imgBackGroundS2: {
    width: '100%',
    padding: '0 0 2% 0',
    margin: '0',
  },
  section2: {
    backgroundPosition: '90px -300px',
    backgroundImage: `url(${IMAGES.backgroundS2})`,
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    '@media (max-width: 1400px)': {
      background: 'none',
    },
  },
  imgLast: {
    width: '55%',
    objectFit: 'contain',
    '@media (max-width: 768px)': {
      width: '100%',
      padding: '20px',
    },
  },
  introduce: {
    width: '100%',
    margin: 'auto',
    marginRight: '5%',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  introduceH2: {
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '42px',
    color: '#2E4799',
    marginBottom: '5%',
  },
  introduceP: {
    fontSize: '18px',
    lineHeight: '26px',
    textAlign: 'justify',
    color: '#4B4B4B',
    marginBottom: '10%',
  },
  section3: {
    padding: '3% 5% 0 5%',
    background: '#fff',
  },
  s3H2: {
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '45px',
    color: '#4B4B4B',
    textAlign: 'center',
    marginBottom: '5%',
  },
  positionButton: {
    '@media (max-width: 768px)': {
      display: 'grid',
    },
  },
  guideCreate: {
    display: 'flex',
    paddingBottom: '9%',
    '@media (max-width: 1920px)': {
      justifyContent: 'center',
    },
    '@media (min-width: 1920px)': {
      justifyContent: 'center',
    },
    '@media (max-width: 768px)': {
      display: 'block',
    },
  },
  guideCreateImg: {
    objectFit: 'contain',
    maxWidth: '45%',
    '@media (max-width: 768px)': {
      width: '100%',
      maxWidth: 'unset',
    },
  },
  contentGuideCreate: {
    width: '35%',
    paddingLeft: '5%',
    margin: 'auto 0',
    backgroundImage: `url(${IMAGES.backgroundContent})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '@media (min-width: 1920px)': {
      width: '37%',
      margin: '0',
      padding: '5%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      paddingLeft: '0px',
    },
  },
  s3H4: {
    fontSize: '30px',
    lineHeight: '35px',
    color: '#4B4B4B',
    fontWeight: 'bold',
    marginBottom: '5%',
  },
  s3P: {
    fontSize: '18px',
    lineHeight: '26px',
    textAlign: 'justify',
    color: '#4B4B4B',
    marginBottom: '5%',
  },
  guideTemplate: {
    display: 'flex',
    marginBottom: '5%',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  guideTemplateImg: {
    margin: '0',
    maxWidth: '50%',
    '@media (max-width: 768px)': {
      width: '100%',
      maxWidth: 'unset',
      order: -1,
    },
  },
  contentGuideTemplate: {
    width: '42%',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '5%',
    backgroundImage: `url(${IMAGES.backgroundContent})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '@media (min-width: 1920px)': {
      width: '30%',
      margin: 'auto 65px',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  contentGuideTemplateP: {
    width: '86%',
  },
  guideMc: {
    display: 'flex',
    marginBottom: '7%',
    '@media (max-width: 1920px)': {
      justifyContent: 'center',
    },
    '@media (min-width: 1920px)': {
      justifyContent: 'center',
    },
    '@media (max-width: 768px)': {
      display: 'block',
      marginTop: '24px',
    },
  },
  contentGuideMc: {
    width: '45%',
    margin: 'auto 0',
    paddingLeft: '5%',
    mixBlendMode: 'darken',
    backgroundImage: `url(${IMAGES.backgroundContent})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '@media (min-width: 1920px)': {
      mixBlendMode: 'darken',
      margin: '0',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      padding: '0',
      transform: 'none',
    },
  },
  imgContentImgFirst: {
    width: '100%',
  },
  imgContentImgLast: {
    width: '55%',
    objectFit: 'contain',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  section4: {
    padding: '3% 5%',
    background: `url(${IMAGES.Vector}) no-repeat`,
    backgroundPosition: 'top right',
    '@media (min-width: 1920px)': {
      height: 'max-content',
    },
    '@media (max-width: 1400px)': {
      height: 'auto',
    },
    '@media (max-width: 768px)': {
      textAlign: 'center',
      backgroundPosition: 'bottom right',
    },
  },
  logoAiS4: {
    position: 'absolute',
    padding: '0 15px 15px 15px',
    right: '50px',
    '@media (max-width: 768px)': {
      position: 'relative',
      right: '0',
    },
  },
  s4H2: {
    fontSize: '25px',
    lineHeight: '45px',
    color: '#4B4B4B',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '80px',
    '@media (max-width: 425px)': {
      marginBottom: '40px',
    },
  },
  guideCreateVideoAiText: {
    display: 'flex',
    overflow: 'hidden',
    '@media (max-width: 768px)': {
      display: 'block',
      textAlign: 'center',
    },
  },
  videoAiTextImgFirst: {
    width: 'calc(65% + 50px)',
    borderRadius: '180px',
    '@media (max-width: 375px)': {
      width: '85%',
    },
  },
  videoAiTextImgLast: {
    transform: 'translate(112%, -95%)',
    mixBlendMode: 'color-burn',
  },
  guideCreateVideoAiContent: {
    width: '40%',
    padding: '15px',
    textAlign: 'center',
    '@media (max-width: 1400px)': {
      width: '100%',
    },
  },
  pd4: {
    padding: '4%',
    width: '80%',
    margin: 'auto',
    '@media (max-width: 425px)': {
      width: '100%',
    },
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  descTextSpan: {
    border: '1px solid',
    borderRadius: '120px',
    background: '#005AEB',
    color: '#fff',
    width: '24px',
    textAlign: 'center',
    marginRight: '10px',
    fontSize: '16px',
    display: 'block',
  },
  titleDescH5: {
    fontWeight: 'bold',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    color: '#4B4B4B',
  },
  descTextP: {
    fontSize: '14px',
    lineHeight: '26px',
    textAlign: 'justify',
    color: '#4B4B4B',
  },
  guideCreateVideoAiButton: {
    margin: 'auto',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      display: 'grid',
      padding: '25px',
    },
  },
  guideCreateVideoAiButtonTag: {
    background: '#2E4799',
    borderRadius: '10px',
    border: 'none',
    padding: '25px',
    fontWeight: 'bold',
    fontSize: '20px',
    alignItems: 'center',
  },
  guideCreateVideoAiButtonAFirst: {
    textDecoration: 'none',
    color: '#fff',
    whiteSpace: 'nowrap',
  },
  guideCreateVideoAiButtonALast: {
    fontWeight: 'bold',
    fontSize: '25px',
    lineHeight: '42px',
    color: '#4B4B4B',
    textDecoration: 'none',
    marginLeft: '2%',
    '@media (max-width: 1400px)': {
      margin: '5%',
    },
  },
  section5: {
    padding: '3% 0',
    background: '#fff',
  },
  s5H2: {
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '42px',
    textAlign: 'center',
    color: '#4B4B4B',
    padding: '15px',
  },
  section5P: {
    marginBottom: '5%',
    fontSize: '18px',
    lineHeight: '26px',
    textAlign: 'center',
    color: '#4B4B4B',
    '@media (max-width: 425px)': {
      padding: '15px',
    },
  },
  contentIntroduceLanguage: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 1400px)': {
      display: 'block',
    },
  },
  contentIntroduceLanguageColumn: {
    width: '40%',
    fontSize: '20px',
    lineHeight: '28px',
    color: '#4B4B4B',
    marginRight: '3%',
    '@media (max-width: 1400px)': {
      width: '56%',
      margin: 'auto !important',
    },
    '@media (min-width: 1920px)': {
      width: '30%',
    },
    '@media (max-width: 425px)': {
      width: '100%',
      padding: '15px',
    },
  },

  backgroundMc: {
    borderRadius: '10px',
    width: '100%',
  },

  contentIntroduceLanguageColumnH4: {
    fontSize: '24px',
    lineHeight: '28px',
    color: '#4B4B4B',
    margin: '3% 0',
  },

  contentIntroduceLanguageColumnP: {
    fontSize: '14px',
    lineHeight: '26px',
    color: '#4B4B4B',
  },

  section6: {
    backgroundImage: `url(${IMAGES.backgroundKol})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 'max-content',
    '@media (max-width: 1400px)': {
      height: 'auto',
      margin: 'auto',
      textAlign: 'center',
      backgroundRepeat: 'repeat-y',
    },
  },

  darkBackground: {
    background: 'rgba(0, 0, 0, 0.4)',
    height: '100%',
  },

  s6H2: {
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '42px',
    textAlign: 'center',
    color: '#FFFFFF',
    padding: '4%',
    margin: '0',
    '@media (max-width: 1400px)': {
      fontSize: '25px',
    },
  },

  kolStudio: {
    display: 'flex',
    margin: '0 5%',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
      display: 'grid',
      margin: 'auto',
      textAlign: 'center',
    },
  },

  kolStudioH4: {
    fontSize: '24px',
    lineHeight: '28px',
    color: '#FFFFFF',
    margin: '15px 0',
    textAlign: 'start',
    '@media (max-width: 1400px)': {
      textAlign: 'center',
    },
  },

  kolStudioP: {
    fontSize: '16px',
    lineHeight: '26px',
    color: '#FFFFFF',
    textAlign: 'start',
    '@media (max-width: 1400px)': {
      margin: 'auto',
      width: '100%',
    },
  },

  kolStudioA: {
    color: '#fff',
    fontWeight: 'bold',
  },
  demoBlack: {
    color: '#4B4B4B',
    fontWeight: 'bold',
  },

  kolMember: {
    width: '30%',
    padding: '15px',
    '@media (min-width: 1920px)': {
      width: '29%',
      padding: '15px',
    },
    '@media (max-width: 1400px)': {
      width: '100%',
    },
    '@media (max-width: 768px)': {
      margin: '25px 0',
    },
  },
  kolMemberImg: {
    width: '100%',
    borderRadius: '15px',
  },

  s6Button: {
    background: 'rgba(255, 255, 255, 0.3)',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: '15px 25px',
    '@media (max-width: 1400px)': {
      margin: '30px 0',
    },
  },

  s6ButtonA: {
    textDecoration: 'none',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '26px',
  },

  more: {
    textAlign: 'center',
    padding: '5% 0',
  },

  s7H2: {
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '42px',
    textAlign: 'center',
    color: '#4B4B4B',
    marginBottom: '3%',
  },

  s7PFirst: {
    fontSize: '18px',
    lineHeight: '26px',
    textAlign: 'center',
    color: '#4B4B4B',
    marginBottom: '7%',
    '@media (max-width: 425px)': {
      padding: '15px',
    },
  },

  section7: {
    backgroundImage: `url(${IMAGES.connectAi})`,
    backgroundRepeat: 'no-repeat',
    height: 'auto',
    backgroundPositionX: 'right',
    backgroundPositionY: '-30px',
    padding: '8% 0',
    '@media (max-width: 1400px)': {
      height: 'auto',
    },
    '@media (max-width: 768px)': {
      background: '#fff',
    },
    '@media (max-width: 425px)': {
      padding: '0 20px',
    },
  },

  contentSection7: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 1400px)': {
      display: 'block',
    },
  },

  contentSection7Column: {
    width: '45%',
    overflow: 'hidden',
    textAlign: 'center',
    padding: '0 2%',
    '@media (max-width: 1400px)': {
      width: '56%',
      margin: 'auto !important',
    },
    '@media (min-width: 1920px)': {
      width: '30%',
    },
    '@media (max-width: 425px)': {
      width: '100%',
      padding: '15px',
    },
  },

  contentSection7ColumnImg: {
    width: '100%',
  },

  contentSection7ColumnH4: {
    fontSize: '24px',
    lineHeight: '28px',
    color: '#4B4B4B',
    fontWeight: '500',
    textAlign: 'start',
    margin: '3% 0',
  },

  contentSection7ColumnP: {
    textAlign: 'start',
    fontSize: '16px',
    lineHeight: '26px',
    color: '#4B4B4B',
  },

  section8: {
    backgroundImage: `url(${IMAGES.imgPrice})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'hidden',
    textAlign: 'center',
    '@media (max-width: 1400px)': {
      backgroundRepeat: 'repeat-y',
      height: 'auto',
    },
  },

  logoAi: {
    textAlign: 'right',
    padding: '25px 35px',
    '@media (max-width: 425px)': {
      textAlign: 'center',
    },
  },

  s8H2: {
    margin: '0 15px 15px 15px',
    fontWeight: 'bold',
    fontSize: '34px',
    lineHeight: '42px',
    color: '#FFFFFF',
  },

  textTitle: {
    fontSize: '18px',
    lineHeight: '26px',
    textAlign: 'center',
    color: '#FFFFFF',
    marginBottom: '3%',
  },

  section9: {
    textAlign: 'center',
    padding: '5% 0',
    background: '#fff',
  },

  s9H2: {
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '45px',
    color: '#4B4B4B',
    '@media (max-width: 425px)': {
      padding: '0 15px',
    },
  },
  partnerContainer: {
    maxWidth: '1900px',
    margin: 'auto',
  },
  partnerItem: {
    margin: '10px 20px',
    alignSelf: 'center',
    '& img': {
      objectFit: 'contain',
      width: '100%',
    },
  },
  boxCall: {
    position: 'fixed',
    bottom: '40px',
    left: '20px',
  },
  dialogMobile: {
    '& $modalHeader': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      backgroundColor: primaryColor[0],
      color: '#fff',
      alignItems: 'center',
      padding: '5px 15px',
      '& h3': {
        fontSize: '20px',
        margin: 0,
      },
    },
    '& $modalCloseButton': {
      marginTop: 0,
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: '90%',
    },
    fontSize: '16px',
  },
}));

export const TextBanner = styled.div`
  padding: 12% 10%;
`;

export const Paragraph = styled.p``;

export const ButtonBanner = styled.button`
  background: linear-gradient(180deg, #2ca8e0 0%, #2e4396 100%);
  border-radius: 30px;
  padding: 10px;
  border: none;
  margin-top: 5%;
  mix-blend-mode: lighten;
  @media (max-width: 1400px) {
    mix-blend-mode: darken;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const ButtonSecondary = styled.button`
  background: #2e4799;
  border-radius: 5px;
  height: 48px;
  padding: 15px;
  margin-right: 5%;
  border: none;
  @media (max-width: 768px) {
    width: 100%;
    margin: 15px 0;
  },
`;

export const LinkSecondary = styled.a`
  font-size: 16px;
  text-align: justify;
  letter-spacing: -0.3px;
  color: #ffffff;
  text-decoration: none;
`;

export const ButtonDefault = styled.button`
  border: 1px solid #7b7b7b;
  box-sizing: border-box;
  border-radius: 5px;
  height: 48px;
  padding: 15px;
  background: transparent;
`;

export const LinkDefault = styled.a`
  font-weight: normal;
  font-size: 16px;
  text-align: justify;
  letter-spacing: -0.3px;
  color: #7b7b7b;
  text-decoration: none;
`;

export const ContentVideo = styled.div`
  padding: 3% 5%;
  display: flex;
  background: linear-gradient(
    90deg,
    #e4f1ff 2.94%,
    rgba(202, 227, 255, 0) 93.37%
  );
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const ImgContentFirst = styled.img`
  overflow: hidden;
  transform: translate(15%, -35%);
  position: relative;
  mix-blend-mode: color-burn;
`;

export const ImgContentLast = styled.img`
  width: 50%;
`;
