import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '4px',
    border: '1px solid #2E4799',
    paddingRight: '14px',
  },
  text: {
    padding: '8px 14px',
  },
}));
