import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  boxShadow,
  drawerWidth,
  transition,
  whiteColor,
  grayColor,
  hexToRgb,
} from 'assets/jss/material-dashboard-pro-react.js';
import customDropdownStyle from './customDropdownStyle';

const pagesHeaderStyle = (theme) => ({
  ...customDropdownStyle(theme),
  appBar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'fixed',
    width: '100%',
    height: '110px',
    paddingTop: '10px',
    zIndex: '1029',
    color: grayColor[6],
    border: '0',
    borderRadius: '3px',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '75px',
    },
  },
  container: {
    ...container,
    minHeight: '50px',
    '@media (max-width: 425px)': {
      margin: '0',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: whiteColor,
    letterSpacing: 'unset',
    '&:hover,&:focus': {
      background: 'transparent',
    },
  },
  appResponsive: {
    top: '8px',
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  list: {
    ...defaultFont,
    fontSize: '14px',
    margin: 0,
    marginRight: '-15px',
    paddingLeft: '0',
    listStyle: 'none',
    color: whiteColor,
    paddingTop: '0',
    paddingBottom: '0',
    display: 'flex',
    '@media (max-width: 980px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  listItem: {
    float: 'left',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      zIndex: '999',
      width: '100%',
      paddingRight: '15px',
    },
  },
  navLink: {
    color: '#4B4B4B',
    margin: '0 5px',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontWeight: '400',
    fontSize: '15px',
    textTransform: 'capitalize',
    borderRadius: '3px',
    lineHeight: '20px',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&:hover,&:focus': {
      color: '#3157A7',
      background: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
    },
  },
  borderRegister: {
    border: '1px solid #006FF2',
    boxSizing: 'border-box',
    borderRadius: '8px',
    color: '#006FF2',
    '@media (max-width: 1400px)': {
      color: '#4B4B4B',
      border: 'none',
    },
  },
  borderLogin: {
    border: '1px solid #C5C5C5',
    boxSizing: 'border-box',
    borderRadius: '8px',
    '@media (max-width: 1400px)': {
      color: '#4B4B4B',
      border: 'none',
    },
  },
  listItemIcon: {
    marginTop: '-3px',
    top: '0px',
    position: 'relative',
    marginRight: '3px',
    width: '20px',
    height: '20px',
    verticalAlign: 'middle',
    color: 'inherit',
    display: 'inline-block',
  },
  listItemText: {
    flex: 'none',
    padding: '0',
    minWidth: '0',
    margin: 0,
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
    color: '#4B4B4B',
  },
  navLinkActive: {
    color: '#3157A7',
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
  },
  drawerPaper: {
    border: 'none',
    bottom: '0',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    ...boxShadow,
    width: drawerWidth,
    ...boxShadow,
    position: 'fixed',
    display: 'block',
    top: '0',
    height: '100vh',
    right: '0',
    left: 'auto',
    visibility: 'visible',
    overflowY: 'visible',
    scrollBehavior: 'smooth',
    borderTop: 'none',
    textAlign: 'left',
    paddingRight: '0px',
    paddingLeft: '0',
    ...transition,
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0',
    },
    '&:after': {
      background: whiteColor,
      opacity: '.8',
    },
  },
  sidebarButton: {
    // backgroundImage: `url(${IMAGES.menuIcon})`,
  },
  managerClasses: {
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    },
  },
  buttonLink: {
    textTransform: 'none',
    fontSize: '15px',
    padding: '15px',
    margin: '0px 5px',
    '&:hover,&:focus': {
      color: whiteColor,
      background: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 10px)',
      '& .MuiListItemText-root': {
        width: 'inherit',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
  caret: {
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '8px',
    verticalAlign: 'middle',
    borderTop: '5px solid',
    borderRight: '5px solid transparent',
    borderLeft: '5px solid transparent',
    color: '#4B4B4B',
  },
  dropdown: {
    minWidth: '160px',
  },
  collapseItem: {
    color: '#4B4B4B',
  },
  logo: {
    '@media (max-width: 600px)': {
      height: '32px',
    },
  },
  accountContainer: {
    '@media (max-width: 980px)': {
      order: '-1',
    },
  },
  userSettings: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '28px',
    '& > div': {
      textAlign: 'right',
    },
    '@media (max-width: 980px)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      marginLeft: '0px',
      borderBottom: '1px solid #DBDBDB',
      padding: '8px',
    },
  },
  usename: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#4B4B4B',
    textAlign: 'right',
  },
  upVip: {
    display: 'inline-flex',
    marginTop: '5px',
    '& a': {
      background: 'linear-gradient(180deg, #2ca8e0 0%, #2e4396 100%)',
      borderRadius: '30px',
      border: 'none',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '26px',
      display: 'flex',
      alignItems: 'center',
      color: '#ffffff',
      padding: '0px 9px',
      cursor: 'pointer',
      '&:hover,&:focus': {
        color: '#ffffff',
        textDecoration: 'none',
      },
      '& img': {
        marginLeft: '5px',
      },
    },
  },
  avatar: {
    marginLeft: '16px',
    padding: '0px',
    borderRadius: '50%',
    '& img': {
      width: '46px',
      borderRadius: '50%',
      height: '46px',
      objectFit: 'cover',
    },
  },
});

export default pagesHeaderStyle;
