import { combineReducers } from 'redux';
import auth, { initialState as authInitialState } from './auth/reducer';
import payment, {
  initialState as paymentInitialState,
} from './payment/reducer';

export const initialState = {
  auth: authInitialState,
  payment: paymentInitialState,
};

export default combineReducers({
  auth,
  payment,
});
