import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import { ROUTES, TIME } from '../constants';
import GuestLayout from '../containers/GuestLayout';
import { setCookie } from '../utils/cookie';

export default function PublicRoute({
  component: Component,
  restricted,
  ...rest
}) {
  const accessToken = useSelector((state) => state.auth.accessToken);

  const { search, pathname } = useLocation();

  const getTokenFromUrl = () => {
    const { token } = queryString.parse(search);
    if (token) {
      window.open(ROUTES.VIDEOS, '_self');
      setCookie('accessToken', token, TIME.A_WEEK);
    }
  };

  useEffect(() => {
    if (pathname !== ROUTES.HOME) return;
    getTokenFromUrl();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken && restricted ? (
          <Redirect to={ROUTES.VIDEOS} />
        ) : (
          <GuestLayout>
            <Component {...props} />
          </GuestLayout>
        )
      }
    />
  );
}
