import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Sidebar from 'components/Video/CreateVideo/Sidebar';
import Asidebar from 'components/Video/CreateVideo/Asidebar';
import MainContent from 'components/Video/CreateVideo/MainContent';
import NoPermission from 'components/NoPermission';

import styles from './index.style';
import apis from '../../../apis';
import { omitIsNil } from '../../../utils/omit';
import { ROUTES } from '../../../constants';
const useStyles = makeStyles(styles);

export default function CreateVideo() {
  const classes = useStyles();

  const { t } = useTranslation('createTemplate');
  const history = useHistory();
  const location = useLocation();

  const { userInfo } = useSelector((state) => state.auth);
  const { packageId, maxText } = userInfo;

  const [templates, setTemplates] = useState([]);
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(null);
  const [voiceInfo, setVoiceInfo] = useState({});
  const [listMc, setListMc] = useState([]);
  const [logo, setLogo] = useState('');
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [content, setContent] = useState('');
  const [videoIntro, setVideoIntro] = useState(null);
  const [videoOutro, setVideoOutro] = useState(null);
  const [selectedMc, setSelectedMc] = useState(0);
  const [selectedClothing, setSelectedClothing] = useState(0);
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [loadingCreated, setLoadingCreated] = useState(false);

  const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  const templateIds = useMemo(() => {
    const ids = templates.map(({ id }) => id);
    return ids;
  }, [templates.length]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    let { templateId, mcId } = params;
    if (!templateId || !templateIds.length || !listMc.length) return;

    const templateIndex = templateIds.findIndex(
      (id) => id.toString() === templateId,
    );
    if (templateIndex === -1) return;

    const mcTemplate = listMc.map((mc) => {
      const { list: newList } = mc;
      return {
        ...mc,
        list: newList.filter(
          ({ templateId: id }) => id.toString() === templateId,
        ),
      };
    });

    let mcIndex = 0;
    let clothingIndex = 0;
    for (let index = 0; index < mcTemplate.length; index++) {
      const { list } = mcTemplate[index];
      const findIndex = list.findIndex((ele) => ele.id.toString() === mcId);
      if (findIndex != -1) {
        mcIndex = index;
        clothingIndex = findIndex;
        break;
      }
    }

    setCurrentTemplateIndex(templateIndex);
    setSelectedMc(mcIndex);
    setSelectedClothing(clothingIndex);
  }, [location, templateIds, listMc]);

  const currentTemplate = useMemo(() => {
    if (!templates || currentTemplateIndex === null) return null;
    return templates[currentTemplateIndex];
  }, [templates, currentTemplateIndex]);

  const listMcTemplate = useMemo(() => {
    if (listMc.length === 0 || !currentTemplate) return [];
    return listMc.map((mc) => {
      const { list: newList } = mc;
      return {
        ...mc,
        list: newList.filter(({ templateId }) => templateId === 34),
      };
    });
  }, [listMc, currentTemplate]);

  const fetchListTemplates = async (type) => {
    const params = {
      type: type,
    };
    try {
      const res = await apis.resource.getListTemplates(params);
      const { error, list } = res;
      if (error === 0) {
        const newData = list.map((ele) => ({
          ...ele,
          listScene: [
            {
              text: '',
              selectedLang: 'vn',
              selectedVoice: 0,
              rate: 1.0,
              images: [],
            },
          ],
          selectedScene: 0,
        }));
        setTemplates(newData);
        setCurrentTemplateIndex(0);
      }
    } catch (_) { }
  };

  const getTypeTemplate = useCallback((type) => {
    fetchListTemplates(type);
  }, []);

  const fetchListVoices = async () => {
    try {
      const res = await apis.voice.getListVoice();
      const { error, voice_info } = res;
      if (error === 0) {
        setVoiceInfo(voice_info);
      }
    } catch (_) { }
  };

  const fetchListMc = async () => {
    try {
      const res = await apis.resource.getMCTemplate();
      const { error, list } = res;
      if (error === 0) {
        setListMc(list);
      }
    } catch (_) { }
  };

  useEffect(() => {
    fetchListTemplates();
    fetchListVoices();
    fetchListMc();
  }, []);

  const handleUpdateTemplate = (template) => {
    setTemplates((prev) => {
      const newTemplates = [...prev];
      newTemplates.splice(currentTemplateIndex, 1, template);
      return newTemplates;
    });
  };

  const handleSelectTemplate = (index) => {
    setCurrentTemplateIndex(index);
    setSelectedMc(0);
    setSelectedClothing(0);
  };

  const checkValidParams = () => {
    const errors = [];
    // if (title.trim().length === 0) {
    //   errors.push(t('missingTitle'));
    // }
    // if (subTitle.trim().length === 0) {
    //   errors.push(t('missingSubTitle'));
    // }
    // if (content.trim().length === 0) {
    //   errors.push(t('missingContent'));
    // }

    if (!currentTemplate) {
      errors.push(t('missingTemplate'));
    }

    let mc;
    if (listMcTemplate.length && selectedMc !== null) {
      mc = listMcTemplate[selectedMc];
    }
    if (!mc) {
      errors.push(t('missingMc'));
    }

    let clothing;
    if (mc && selectedClothing !== null) {
      clothing = mc.list[selectedClothing];
    }
    if (!clothing) {
      errors.push(t('missingClothing'));
    }

    const { listScene = [] } = currentTemplate || {};
    let totalScriptLength = 0;
    if (listScene.length) {
      for (let index = 0; index < listScene.length; index++) {
        const { text, audio, images } = listScene[index];

        let hasScript = true;
        if (!audio) {
          totalScriptLength += text.length;
          if (text.trim().length === 0) {
            hasScript = false;
          }
        }

        if (!hasScript || images.length === 0) {
          let errorText = '';
          if (!hasScript) {
            errorText = t('script');
          }
          if (images.length === 0) {
            if (errorText) {
              errorText += ` ${t('and')} ${t('image')}`;
            } else {
              errorText = t('image');
            }
          }
          errors.push(
            `${t('scene')} ${index + 1} ${t('isMissing')}: ${errorText}`,
          );
        }
      }

      if (totalScriptLength > maxText) {
        errors.push(t('maxLengthScriptError', { maxLength: maxText }));
      }
    }

    if (errors.length) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
        >
          {errors.map((text, index) => {
            return <p key={index}>{text}</p>;
          })}
        </SweetAlert>,
      );
      return false;
    }
    return true;
  };

  const getVoice = (selectedLang, selectedVoice) => {
    if (!voiceInfo) return '';

    const language = voiceInfo[selectedLang];
    if (!language) return '';

    const voices = Object.keys(language.list);
    if (!voices.length) return '';

    return voices[selectedVoice];
  };

  const onConfirm = async () => {
    hideAlert();
    setExporting(true);
    setLoadingCreated(true);

    const { listScene = [], id: templateId } = currentTemplate || {};
    const scripts = await Promise.all(
      listScene.map(async (scene) => {
        try {
          const { text, rate, selectedLang, selectedVoice, audio, images } =
            scene;
          const script = {};
          if (audio) {
            const formData = new FormData();
            formData.append('image', audio);
            const res = await apis.resource.uploadFile({
              formData,
            });
            const { path } = res || {};
            script.audio = path;
          } else {
            script.text = text.trim();
            script.rate = rate.toString();
            const voice = getVoice(selectedLang, selectedVoice);
            script.voice = voice;
          }

          const uploadedImages = await Promise.all(
            images.map(async (image) => {
              const { file } = image;
              try {
                const formData = new FormData();
                formData.append('image', file);
                const res = await apis.resource.uploadFile({
                  formData,
                });
                const { path } = res || {};
                return path;
              } catch (error) { }
            }),
          );
          script.images = uploadedImages.filter((ele) => ele);
          return script;
        } catch (error) { }
      }),
    );

    try {
      const mc = listMcTemplate[selectedMc];
      const clothing = mc.list[selectedClothing];

      const videoData = {
        title: title.trim(),
        subject: subTitle.trim(),
        body: content.trim(),
        scripts,
        template_id: templateId,
        logo,
        intro: videoIntro,
        outro: videoOutro,
        mc_id: clothing.id,
      };
      omitIsNil(videoData, { deep: false });
      const res = await apis.video.createVideoTemplate(videoData);
      const { error, errorMsg } = res;
      if (error === 0) {
        history.push(ROUTES.VIDEOS);
      } else {
        setAlert(
          <SweetAlert
            danger
            style={{ display: 'block', marginTop: '-100px' }}
            title={t('notification')}
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
          >
            {/* {t('serverError')} */}
            {errorMsg}
          </SweetAlert>,
        );
      }
    } catch (error) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
        >
          {t('serverError')}
        </SweetAlert>,
      );
    }
    setLoadingCreated(false);
    setExporting(false);
  };

  const handleCreateVideo = async () => {
    if (!acceptPolicy) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={t('notification')}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText="Đóng"
        >
          <p>
            {t('needAgreeWith')}
            <Link to={ROUTES.POLICY} target="_blank">
              {t('policyAndTerms')}
            </Link>{' '}
            {t('ofAICLIP')}
          </p>
        </SweetAlert>,
      );
      return;
    }

    const isValid = checkValidParams();
    if (!isValid) return;

    setAlert(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('warning')}
        onConfirm={() => onConfirm()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        confirmBtnText={t('iGetIt')}
      >
        <p> {t('warningContent')}</p>
      </SweetAlert>,
    );
  };

  if (!packageId) {
    return (
      <NoPermission
        backText={t('backToVideo')}
        handleBack={() => {
          history.push(ROUTES.VIDEOS);
        }}
      />
    );
  }

  return (
    <div>
      <GridContainer justify="center" className={classes.container}>
        <GridItem xs={12} sm={4} md={2} className={classes.maxHeight}>
          <Sidebar
            currentTemplate={currentTemplate}
            handleUpdateTemplate={handleUpdateTemplate}
            handleCreateVideo={handleCreateVideo}
            exporting={exporting}
            acceptPolicy={acceptPolicy}
            setAcceptPolicy={setAcceptPolicy}
            loadingCreated={loadingCreated}
          />
        </GridItem>
        <GridItem xs={12} sm={8} md={7} className={classes.maxHeight}>
          <MainContent
            voiceInfo={voiceInfo}
            currentTemplate={currentTemplate}
            handleUpdateTemplate={handleUpdateTemplate}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Asidebar
            templates={templates}
            currentTemplate={currentTemplate}
            handleSelectTemplate={handleSelectTemplate}
            listMc={listMcTemplate}
            logo={logo}
            setLogo={setLogo}
            title={title}
            setTitle={setTitle}
            subTitle={subTitle}
            setSubTitle={setSubTitle}
            content={content}
            setContent={setContent}
            videoIntro={videoIntro}
            setVideoIntro={setVideoIntro}
            videoOutro={videoOutro}
            setVideoOutro={setVideoOutro}
            selectedMc={selectedMc}
            setSelectedMc={setSelectedMc}
            selectedClothing={selectedClothing}
            setSelectedClothing={setSelectedClothing}
            getTypeTemplate={getTypeTemplate}
          />
        </GridItem>
      </GridContainer>
      {alert}
    </div>
  );
}
