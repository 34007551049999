import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import GridContainer from 'components/Grid/GridContainer';
import PricingItem from 'components/PricingItem';
import CustomButton from 'components/CustomButtons/Button';
import actions from '../../redux/actions';
import {
  ButtonDefault,
  ButtonSecondary,
  LinkDefault,
  LinkSecondary,
  ButtonBanner,
  ContentVideo,
} from './index.style';
import { IMAGES, ROUTES } from '../../constants';
import useStyles from './index.style';
import {
  API_DOMAIN,
  FACEBOOK_APP_ID,
  FACEBOOK_PAGE_ID,
  PHONE_NUMBER_CSKH,
} from '../../configs';

const mapPricingTypes = {
  'Miễn Phí': 'free',
  'Tiết Kiệm': 'save',
  'Trải Nghiệm': 'experience',
  'Khuyên Dùng': 'recommend',
  Pro: 'pro',
  'Doanh Nghiệp': 'enterprise',
};

const Home = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(['home']);

  const partners = [
    IMAGES.viettel,
    IMAGES.mobifone,
    IMAGES.tuoitre,
    IMAGES.nguoiduatin,
    IMAGES.ttttThaiBinh,
    IMAGES.ttttHaTinh,
    IMAGES.tttBinhDinh,
    IMAGES.ttttThaiNguyen,
    IMAGES.tttGiaLai,
    IMAGES.ttttPhuYen,
    IMAGES.quangNgai,
    IMAGES.tckd,
    IMAGES.ptthLaoCai,
  ];

  const { fetchingPackage, packages } = useSelector((state) => state.payment);

  const [showDialogMobile, setShowDialogMobile] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.payment.getListPackages());
  }, []);

  const changeLanguage = (namespace) => async () => {
    await i18n.changeLanguage(namespace);
    localStorage.setItem('i18nextLng', namespace);
  };

  useEffect(() => {
    changeLanguage('vi')();
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      setShowDialogMobile(true);
    } else {
      setShowDialogMobile(false);
    }
  }, []);

  const formatPrice = (value, isSale) => {
    if (isSale && !value) return '';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const removeTag = (text) => {
    var regex = /(<([^>]+)>)/gi;
    return text.replace(regex, '');
  };

  const getFeatures = (desc) => {
    return desc.split('\n').map(removeTag);
  };

  if (fetchingPackage) {
    return (
      <div
        className={classNames({
          [classes.background]: true,
          [classes.loading]: true,
        })}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={classes.homePage}>
      <div className={classes.backgroundBanner}>
        <img className={classes.bannerHome} src={IMAGES.bannerHome} alt="img" />
        <div className={classes.textBanner}>
          <h1 className={classes.bigTitleBanner}>{t('bigTitleBanner')} </h1>
          <p className={classes.paragraphBanner}>{t('paragraphBanner1')}</p>
          <ButtonBanner>
            <Link className={classes.bannerLink} to={ROUTES.VIDEOS}>
              {t('experientButton')}
            </Link>
          </ButtonBanner>
        </div>
      </div>
      <div className={classes.wrapIframeVideo}>
        <img src={IMAGES.bgYoutube} alt="" className={classes.imgBgYoutube} />
        <iframe
          className={classes.iframeVideo}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/CHYwBM47SnM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      {/* <img
        className={classes.imgBackGroundS2}
        src={IMAGES.imgBackGroundS2}
        alt="background-img"
      /> */}
      <section className={classes.section4}>
        <div className="guide-detail-create-video">
          <img className={classes.logoAiS4} src={IMAGES.logo} alt="img" />
          <h2 className={classes.s4H2}>{t('videoAi3Way')}</h2>
          <div className={classes.guideCreateVideoAiText}>
            <div className={classes.guideCreateVideoAiContent}>
              <div>
                <img
                  className={classes.videoAiTextImgFirst}
                  src={IMAGES.typeVideo}
                  alt="img"
                />
              </div>
              <div className={classes.pd4}>
                <div className={classes.dFlex}>
                  <span className={classes.descTextSpan}>1</span>
                  <h5 className={classes.titleDescH5}>
                    {t('selectTypeVideo')}
                  </h5>
                </div>
                <p className={classes.descTextP}>{t('descTextP')}</p>
              </div>
            </div>
            <div className={classes.guideCreateVideoAiContent}>
              <div>
                <img
                  className={classes.videoAiTextImgFirst}
                  src={IMAGES.selectMc}
                  alt="img"
                />
              </div>
              <div className={classes.pd4}>
                <div className={classes.dFlex}>
                  <span className={classes.descTextSpan}>2</span>
                  <h5 className={classes.titleDescH5}>{t('selectMcAi')}</h5>
                </div>
                <p className={classes.descTextP}>{t('descMcAi')}</p>
              </div>
            </div>
            <div className={classes.guideCreateVideoAiContent}>
              <div>
                <img
                  className={classes.videoAiTextImgFirst}
                  src={IMAGES.videoContent}
                  alt="img"
                />
              </div>
              <div className={classes.pd4}>
                <div className={classes.dFlex}>
                  <span className={classes.descTextSpan}>3</span>
                  <h5 className={classes.titleDescH5}>
                    {t('contentAndExport')}
                  </h5>
                </div>
                <p className={classes.descTextP}>{t('textGuideCreateVideo')}</p>
              </div>
            </div>
          </div>
          <div className={classes.guideCreateVideoAiButton}>
            <button className={classes.guideCreateVideoAiButtonTag}>
              <Link
                className={classes.guideCreateVideoAiButtonAFirst}
                to={ROUTES.VIDEOS}
              >
                {t('createAccount')}
              </Link>
            </button>
            <Link
              className={classes.guideCreateVideoAiButtonALast}
              to={ROUTES.GREEN_SCREEN}
            >
              {t('videoDemo')}
            </Link>
          </div>
        </div>
      </section>
      <section className={classes.section3}>
        <h2 className={classes.s3H2}>{t('titleDesc')}</h2>
        <div className={classes.guideCreate}>
          <img
            className={classes.guideCreateImg}
            src={IMAGES.guideCreate}
            alt="img"
          />
          <div className={classes.contentGuideCreate}>
            <h4 className={classes.s3H4}>{t('titleGuideCreate')}</h4>
            <p className={classes.s3P}>{t('contentGuideCreate')}</p>
            <div className={classes.positionButton}>
              <ButtonSecondary>
                <LinkSecondary href={ROUTES.GREEN_SCREEN}>
                  {t('createVideoFree')}
                </LinkSecondary>
              </ButtonSecondary>
              <ButtonDefault>
                <LinkDefault
                  href="https://www.youtube.com/playlist?list=PLcE0yt7NYtDpQlDO6F85yltfaNSI14_hB"
                  target="_blank"
                >
                  {t('guide')}
                </LinkDefault>
              </ButtonDefault>
            </div>
          </div>
        </div>
        <div className={classes.guideTemplate}>
          <div className={classes.contentGuideTemplate}>
            <h4 className={classes.s3H4}>{t('titleGuideTemplate')}</h4>
            <p className={classes.s3P}>{t('contentGuideTemplate')}</p>
            <div className={classes.positionButton}>
              <ButtonSecondary>
                <LinkSecondary href={ROUTES.GREEN_SCREEN}>
                  {t('createVideoFree')}
                </LinkSecondary>
              </ButtonSecondary>
              <ButtonDefault>
                <LinkDefault
                  href="https://www.youtube.com/playlist?list=PLcE0yt7NYtDpQlDO6F85yltfaNSI14_hB"
                  target="_blank"
                >
                  {t('guide')}
                </LinkDefault>
              </ButtonDefault>
            </div>
          </div>
          <img
            className={classes.guideTemplateImg}
            src={IMAGES.guideTemplate}
            alt="img"
          />
        </div>
        <div className={classes.guideMc}>
          <img
            className={classes.imgContentImgLast}
            src={IMAGES.guideMc}
            alt="img"
          />
          <div className={classes.contentGuideMc}>
            <h4 className={classes.s3H4}>{t('titleGuideMc')}</h4>
            <p className={classes.s3P}>{t('contentGuideMc')}</p>
            <div className={classes.positionButton}>
              <ButtonSecondary>
                <LinkSecondary href={ROUTES.GREEN_SCREEN}>
                  {t('createVideoFree')}
                </LinkSecondary>
              </ButtonSecondary>
              <ButtonDefault>
                <LinkDefault
                  href="https://www.youtube.com/playlist?list=PLcE0yt7NYtDpQlDO6F85yltfaNSI14_hB"
                  target="_blank"
                >
                  {t('guide')}
                </LinkDefault>
              </ButtonDefault>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.section2}>
        <ContentVideo>
          <div className={classes.introduce}>
            <h2 className={classes.introduceH2}>{t('titleIntroduce')}</h2>
            <p className={classes.introduceP}>{t('contentIntroduce1')}</p>
            <ButtonSecondary>
              <LinkSecondary href={ROUTES.GREEN_SCREEN}>
                {t('createVideoFree')}
              </LinkSecondary>
            </ButtonSecondary>
            <ButtonDefault>
              <LinkDefault
                href="https://www.youtube.com/playlist?list=PLcE0yt7NYtDpQlDO6F85yltfaNSI14_hB"
                target="_blank"
              >
                {t('guide')}
              </LinkDefault>
            </ButtonDefault>
          </div>
          <img
            className={classes.imgLast}
            src={IMAGES.clipNightMode}
            alt="img"
          />
        </ContentVideo>
      </section>
      <section className={classes.section5}>
        <div className="title-section-5">
          <h2 className={classes.s5H2}>{t('titleS5')}</h2>
          <p className={classes.section5P}>
            {t('s5P')} <br /> {t('s5P1')}
          </p>
        </div>
        <div className={classes.contentIntroduceLanguage}>
          <div className={classes.contentIntroduceLanguageColumn}>
            <img className={classes.backgroundMc} src={IMAGES.backgroundMc} />
            <h4 className={classes.contentIntroduceLanguageColumnH4}>
              {t('contentIntroduceLanguageColumnH4')}
            </h4>
            <p className={classes.contentIntroduceLanguageColumnP}>
              {t('contentIntroduceLanguageColumnP')}
              <Link
                to={{
                  pathname: 'https://youtu.be/ydsOUmJvX4U',
                }}
                target="_blank"
                className={classes.demoBlack}
              >
                {t('demo')}
              </Link>
            </p>
          </div>
          <div
            className={classes.contentIntroduceLanguageColumn}
            style={{ margin: '0px' }}
          >
            <img className={classes.backgroundMc} src={IMAGES.backgroundMc2} />
            <h4 className={classes.contentIntroduceLanguageColumnH4}>
              {t('contentIntroduceLanguageColumnH41')}
            </h4>
            <p className={classes.contentIntroduceLanguageColumnP}>
              {t('contentIntroduceLanguageColumnP1')}
              <Link
                to={{
                  pathname: 'https://youtu.be/B_xvMYWUVHM',
                }}
                target="_blank"
                className={classes.demoBlack}
              >
                {t('demo')}
              </Link>
            </p>
          </div>
        </div>
      </section>
      <section className={classes.section6}>
        <div className={classes.darkBackground}>
          <h2 className={classes.s6H2}>{t('titleS6')}</h2>
          <div className={classes.kolStudio}>
            <div className={classes.kolMember}>
              <img
                className={classes.kolMemberImg}
                src={IMAGES.pewPew}
                alt="img-kol"
              />
              <h4 className={classes.kolStudioH4}>{t('titleH4')}</h4>
              <p className={classes.kolStudioP}>
                {t('kolStudioP')}{' '}
                <Link
                  to={{
                    pathname: 'https://youtu.be/cXi125eSJhU',
                  }}
                  target="_blank"
                  className={classes.kolStudioA}
                >
                  {t('demo')}
                </Link>
              </p>
            </div>
            <div className={classes.kolMember}>
              <img
                className={classes.kolMemberImg}
                src={IMAGES.vtv}
                alt="img-kol"
              />
              <h4 className={classes.kolStudioH4}>{t('mc1')}</h4>
              <p className={classes.kolStudioP}>
                {t('kolStudioP1')}{' '}
                <Link
                  to={{
                    pathname: 'https://youtu.be/Dop0DRaeNX0',
                  }}
                  target="_blank"
                  className={classes.kolStudioA}
                >
                  {t('demo')}
                </Link>
              </p>
            </div>
            <div className={classes.kolMember}>
              <img
                className={classes.kolMemberImg}
                src={IMAGES.vtvMc}
                alt="img-kol"
              />
              <h4 className={classes.kolStudioH4}>{t('mc2')}</h4>
              <p className={classes.kolStudioP}>
                {t('kolStudio2')}{' '}
                <Link
                  to={{
                    pathname: 'https://youtu.be/voU7TTELp6E',
                  }}
                  target="_blank"
                  className={classes.kolStudioA}
                >
                  {t('demo')}
                </Link>
              </p>
            </div>
          </div>
          <div className={classes.more}>
            <button className={classes.s6Button}>
              <a className={classes.s6ButtonA} href="tim-hieu-them">
                {t('readMore')}
              </a>
            </button>
          </div>
        </div>
      </section>
      <section className={classes.section7}>
        <h2 className={classes.s7H2}>{t('titleS7')}</h2>
        <p className={classes.s7PFirst}>
          {t('s7P')}
          <br />
          {t('s7P2')}
        </p>
        <div className={classes.contentSection7}>
          <div className={classes.contentSection7Column}>
            <img
              className={classes.contentSection7ColumnImg}
              src={IMAGES.img1}
              alt="img-kol"
            />
            <h4 className={classes.contentSection7ColumnH4}>API AiClip</h4>
            <p className={classes.contentSection7ColumnP}>
              {t('contentSection7ColumnP')}{' '}
              <a href="tim-hieu-them">{t('readMore')} &gt;</a>
            </p>
          </div>
          <div className={classes.contentSection7Column}>
            <img
              className={classes.contentSection7ColumnImg}
              src={IMAGES.img2}
              alt="img-kol"
            />
            <h4 className={classes.contentSection7ColumnH4}>
              {t('brandIdentity')}
            </h4>
            <p className={classes.contentSection7ColumnP}>
              {t('contentSection7ColumnP1')}{' '}
              <a href="tim-hieu-them">{t('readMore')} &gt;</a>
            </p>
          </div>
        </div>
      </section>
      <section className={classes.section8}>
        <div className={classes.darkBackground}>
          <div className={classes.logoAi}>
            <img src={IMAGES.logoAiChuTrang} alt="logo-ai" />
          </div>
          <div className="priceContent">
            <h2 className={classes.s8H2}>{t('quote')}</h2>
            <p className={classes.textTitle}>{t('textTitle')}</p>
            <GridContainer justify="center">
              {packages.map((pkg) => {
                const { id, title, price, sale, desc } = pkg;
                const type = mapPricingTypes[title] || 'save';
                let description = t('recommendPackage');
                if (type === 'free') {
                  description = `10 ${t('timeUse')}`;
                } else if (type === 'experience') {
                  description = t('experiencePackage');
                } else if (type === 'enterprise') {
                  description = t('enterprisePackage');
                }

                return (
                  <PricingItem
                    key={id}
                    type={type}
                    title={title}
                    description={description}
                    price={formatPrice(price)}
                    sale={formatPrice(sale, true)}
                    features={getFeatures(desc)}
                    display="usd"
                    packageId={id}
                  />
                );
              })}
            </GridContainer>
          </div>
        </div>
      </section>
      <section className={classes.section9}>
        <h2 className={classes.s9H2}>{t('s9H2')}</h2>
        <GridContainer justify="center" className={classes.partnerContainer}>
          {partners.map((item, index) => (
            <Grid
              xs={4}
              sm={3}
              md={2}
              lg={1}
              key={index}
              className={classes.partnerItem}
            >
              <img
                className={classes.connectBusinessImg}
                src={item}
                alt="img-connect-business"
              />
            </Grid>
          ))}
        </GridContainer>
      </section>
      <div className={classes.boxCall}>
        <a href={`tel:` + PHONE_NUMBER_CSKH}>
          <svg
            width="79"
            height="79"
            viewBox="0 0 79 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="39.5" cy="39.5" r="39.5" fill="url(#paint0_radial)" />
            <circle cx="39.5" cy="39.5" r="19.5" fill="#F2C94D" />
            <path
              d="M50.9184 45.5366C50.7753 45.3051 50.3978 45.1664 49.8342 44.8891C49.2679 44.6124 46.4843 43.2696 45.9663 43.0858C45.4484 42.9014 45.0683 42.8085 44.6934 43.3625C44.3159 43.9172 43.231 45.1664 42.8999 45.5366C42.5707 45.9074 42.2415 45.9526 41.6753 45.6752C41.1083 45.3986 39.2842 44.811 37.1236 42.9246C35.4424 41.4528 34.3066 39.6393 33.9749 39.0846C33.6463 38.5306 33.9422 38.2307 34.2224 37.954C34.4778 37.7044 34.7893 37.3065 35.0721 36.9841C35.3556 36.659 35.4496 36.4268 35.6384 36.0566C35.8271 35.6864 35.7331 35.364 35.5901 35.0866C35.4496 34.81 34.3171 32.0812 33.8449 30.97C33.3759 29.862 32.9037 30.0458 32.5719 30.0458C32.2428 30.0458 31.8653 30 31.4877 30C31.1102 30 30.4976 30.1387 29.9797 30.6933C29.4617 31.2473 28 32.5901 28 35.3162C28 38.0443 30.026 40.6821 30.3088 41.0497C30.5923 41.4206 34.2224 47.2018 39.9758 49.4217C45.7293 51.641 45.7293 50.9006 46.7671 50.807C47.8029 50.7168 50.1144 49.4668 50.5866 48.1718C51.0588 46.8768 51.0588 45.7662 50.9184 45.5366Z"
              fill="white"
            />
            <defs>
              <radialGradient
                id="paint0_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(39.5 39.5) rotate(90) scale(39.5)"
              >
                <stop
                  offset="0.713542"
                  stopColor="#F2C94D"
                  stopOpacity="0.32"
                />
                <stop offset="1" stopColor="#F2C94D" />
              </radialGradient>
            </defs>
          </svg>
        </a>
      </div>
      <MessengerCustomerChat
        pageId={FACEBOOK_PAGE_ID}
        appId={FACEBOOK_APP_ID}
        htmlRef={API_DOMAIN}
      />
      <Dialog
        className={classes.dialogMobile}
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal,
        }}
        open={Boolean(showDialogMobile)}
        keepMounted
        onClose={() => setShowDialogMobile(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h3
            className={classNames({
              [classes.modalTitle]: true,
              [classes.left]: true,
            })}
          >
            {t('notification')}
          </h3>
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setShowDialogMobile(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </CustomButton>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={`${classes.modalBody} ${classes.removePadding}`}
        >
          <h4>{t('isMobile')}</h4>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Home;
