export default {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  PRICING: '/pricing',
  PAYMENT: '/payment',
  VIDEOS: '/videos',
  CREATE_VIDEO: '/videos/template',
  GREEN_SCREEN: '/videos/green-screen',
  POLICY: '/policy',
  TRANSACTION_HISTORY: '/transaction-history',
  ORDER_VIEW: '/order-view',
  MANAGER_ACCOUNT: '/manager-account',
};
