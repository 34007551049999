import { whiteColor } from 'assets/jss/material-dashboard-pro-react.js';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

const createVideoStyle = {
  container: {
    background: whiteColor,
  },
  maxHeight: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 110px)',
    display: 'flex',
    flexDirection: 'column',
  },
  ...buttonStyle,
};

export default createVideoStyle;
